import { injectable } from 'inversify';
import { PhoneUtils } from '~/utils/phone';
import { ConnectionsUtils } from '~/modules/reportsCommon/reportConnection/utils/connectionsUtils';

import {
  IReportConnectionsFormatRelatedService,
  ReportConnectionRelatedInfo
} from '~/modules/reportsCommon/reportConnection/services/reportConnectionsService.interfaces';
import { ReportConnectionRelatedEntityDTO } from '~/types/dto/common/reportConnectionRelatedEntityDTO';
import { ReportConnectionRelatedRelationDTO } from '~/types/dto/common/reportConnectionRelatedRelationDTO';
import { ReportConnectionRelatedEntityDisplayDTO } from '~/types/dto/common/reportConnectionRelatedEntityDisplayDTO';
import { ConnectionItemInfo } from '~/modules/reportsCommon/reportConnection/types/connectionItemInfo';
import { ConnectionEntitiesInfo } from '~/modules/reportsCommon/reportConnection/types/connectionEntitiesInfo';
import { ConnectionRelationInfo } from '~/modules/reportsCommon/reportConnection/types/connectionRelationInfo';

@injectable()
export class ReportConnectionsFormatRelatedService implements IReportConnectionsFormatRelatedService {
  getFormattedRelated(relatedInfo: ReportConnectionRelatedInfo[]): Array<ConnectionItemInfo> {
    return relatedInfo.map((related): ConnectionItemInfo => {
      const { RelatedEntities, RelatedRelation } = related;

      return {
        Entities: this.formatEntities(RelatedEntities),
        Relation: this.formatRelation(RelatedRelation),
      };
    });
  }

  protected formatEntities(relatedEntities: Array<ReportConnectionRelatedEntityDTO>): Array<ConnectionEntitiesInfo> {
    return relatedEntities.map((entity) => ({
      value: entity.Name,
      jobsInfo: this.formatJobs(entity.DisplayJobs)
    }));
  }
        
  protected formatRelation(relatedRelation: ReportConnectionRelatedRelationDTO): ConnectionRelationInfo {
    const calculatedRelationDepth = ConnectionsUtils.calculationCommunicationLevel(relatedRelation.Depth);
    const relationValue = PhoneUtils.getIsValidPhoneNumber(relatedRelation.DisplayName) 
      ? PhoneUtils.formatPhoneNumberWithDash(relatedRelation.DisplayName) : relatedRelation.DisplayName;

    return {
      relationDepthLevelLabel: ConnectionsUtils.communicationLevel(calculatedRelationDepth),
      relationTypeLabel: relatedRelation.DisplayType,
      relationValue: relationValue,
    };
  }

  protected formatJobs(displayJobs: Array<ReportConnectionRelatedEntityDisplayDTO>) {
    const arrayJobs = displayJobs.map((job) => `${job.Position} ${job.Company}`);

    return arrayJobs.join(', ');
  }
}
