import { injectable, inject } from 'inversify';
import { orderBy } from 'lodash-es';
import {
  IReportColleagueAdapterService,
  IReportColleagueAdapterServiceKey,
  IReportJobAdapterService, IReportPaymentAdapterService,
  IReportPaymentAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportJob } from '~/types/common/reportJob';
import { ReportJobDTO } from '~/types/dto/common/reportJobDTO';
import { StringUtils } from '~/utils/stringUtils';
import { PhoneUtils } from '~/utils/phone';

@injectable()
export class ReportJobAdapterService implements IReportJobAdapterService {
  constructor(
    @inject(IReportPaymentAdapterServiceKey as symbol) private reportPaymentAdapterService: IReportPaymentAdapterService,
    @inject(IReportColleagueAdapterServiceKey as symbol) private reportColleagueAdapterService: IReportColleagueAdapterService
  ) {}
  getData(initData: ReportJob[]): ReportJobDTO[] {
    return orderBy((initData ?? []), ['StartDate'], ['desc']).map((item) => ({
      CompanyName: StringUtils.quoteReplacement(item.CompanyName
        ? item.CompanyName.replaceAll('`', '"')
        : ''),
      CompanyInn: (item.CompanyInn ?? '').length !== 12 ? item.CompanyInn ?? '' : '',
      DepartmentName: StringUtils.quoteReplacement(item.DepartmentName ?? ''),
      Position: StringUtils.quoteReplacement(item.Position ?? ''),
      StartDate: item.StartDate ?? '',
      EndDate: item.EndDate ?? '',
      CompanyAddress: StringUtils.quoteReplacement(item.CompanyAddress ?? ''),
      CompanyPhone: PhoneUtils.getIsValidPhoneNumber(item.CompanyPhone ?? '') ? PhoneUtils.normalizePhoneNumber(item.CompanyPhone ?? '') : '',
      CompanyHeadPhone: PhoneUtils.getIsValidPhoneNumber(item.CompanyHeadPhone ?? '') ? PhoneUtils.normalizePhoneNumber(item.CompanyHeadPhone ?? '') : '',
      CompanyBookkeeperPhone: PhoneUtils.getIsValidPhoneNumber(item.CompanyBookkeeperPhone ?? '') ? PhoneUtils.normalizePhoneNumber(item.CompanyBookkeeperPhone ?? '') : '',
      ContractType: StringUtils.quoteReplacement(item.ContractType),
      ArticleDissmissal: StringUtils.quoteReplacement(item.ArticleDissmissal ?? ''),
      Payments: this.reportPaymentAdapterService.getData(item.Payments),
      Colleagues: this.reportColleagueAdapterService.getData(item.Colleagues),
    })).filter((item) => item.CompanyName);
  }
}
