import { bindCommonAdapterServices } from '~/configuration/inversify/container.commonAdapterServices';
import { IContainerBinder } from './containerAccessor.interface';
import { MetaDataAdapterService } from '~/services/adapters/metaDataAdapterService';
import { IMetaDataAdapterServiceKey } from '~/services/adapters/metaDataAdapterService.interface';
import { IDataListAdapterKey } from '~/services/adapters/dataListAdapterServise.interface';
import { DataListAdapter } from '~/services/adapters/dataListAdapterServise';
import { bindLegalEntitiesAdapterServices } from './container.legalEntitiesAdapterServices';
import { bindIndividualAdapterServices } from '~/configuration/inversify/container.individualAdapterServices';

export function bindAdapterServices(bind: IContainerBinder) {
  bindCommonAdapterServices(bind);
  bindLegalEntitiesAdapterServices(bind);
  bindIndividualAdapterServices(bind);

  bind(IMetaDataAdapterServiceKey).to(MetaDataAdapterService);
  bind(IDataListAdapterKey).to(DataListAdapter);
}
