import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import {
  IReportConnectionParseServiceKey,
  IReportConnectionsFormatRelatedServiceKey
} from '~/modules/reportsCommon/reportConnection/services/reportConnectionsService.interfaces';
import { ReportConnectionsParseService } from '~/modules/reportsCommon/reportConnection/services/reportConnectionsParseService';
import { ReportConnectionsFormatRelatedService } from '~/modules/reportsCommon/reportConnection/services/reportConnectionsFormatRelatedService';

export function bindReportConnectionsServices(bind: IContainerBinder) {
  bind(IReportConnectionParseServiceKey).to(ReportConnectionsParseService);
  bind(IReportConnectionsFormatRelatedServiceKey).to(ReportConnectionsFormatRelatedService);
}
