import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import vueuse_head_polyfill_M7DKUOwKp5 from "/app/node_modules/nuxt/dist/head/runtime/plugins/vueuse-head-polyfill.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import chartjs_1wC9lAM0Tp from "/app/plugins/chartjs.ts";
import ssr_plugin_B4ptqVdIfe from "/app/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  vueuse_head_polyfill_M7DKUOwKp5,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  chartjs_1wC9lAM0Tp,
  ssr_plugin_B4ptqVdIfe
]