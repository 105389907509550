import { InjectionKey } from 'vue';
import { IAdapterService } from '~/services/adapters/adapterService.interfaces';
import { ReportUrl } from '~/types/common/reportUrl';
import { ReportUrlDTO } from '~/types/dto/common/reportUrlDTO';
import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';
import { ReportMainEntityFileDTO } from '~/types/dto/common/reportMainEntityFileDTO';
import { ReportLegalEntitiesParticipation } from '~/types/common/reportLegalEntitiesParticipation';
import { ReportLegalEntitiesParticipationDTO } from '~/types/dto/common/reportLegalEntitiesParticipationDTO';
import { ReportVehicle } from '~/types/common/reportVehicle';
import { ReportVehicleDTO } from '~/types/dto/common/reportVehicleDTO';
import { ReportWantedCarDTO } from '~/types/dto/common/reportWantedCarDTO';
import { ReportVehicleOwnership } from '~/types/common/reportVehicleOwnership';
import { ReportVehicleOwnershipDTO } from '~/types/dto/common/reportVehicleOwnershipDTO';
import { ReportVehicleRelation } from '~/types/common/reportVehicleRelation';
import { ReportVehicleRelationDTO } from '~/types/dto/common/reportVehicleRelationDTO';
import { ReportVehicleInsurance } from '~/types/common/reportVehicleInsurance';
import { ReportVehicleInsuranceDTO } from '~/types/dto/common/reportVehicleInsuranceDTO';
import { ReportRoadAccident } from '~/types/common/reportRoadAccident';
import { ReportRoadAccidentDTO } from '~/types/dto/common/reportRoadAccidentDTO';
import { ReportVehicleRestrict } from '~/types/common/reportVehicleRestrict';
import { ReportPledgesRegistry } from '~/types/common/reportPledgesRegistry';
import { ReportPledgesRegistryDTO } from '~/types/dto/common/reportPledgesRegistry';
import { ReportWantedCar } from '~/types/common/reportWantedCar';
import { ReportVehicleRestrictDTO } from '~/types/dto/common/reportVehicleRestrictDTO';
import { ReportPhotosUrlDTO } from '~/types/dto/common/ReportPhotosUrlDTO';
import { ReportPhotosUrl } from '~/types/common/reportVehiclePhotosUrl';
import { ReportMainEntityFileUrl } from '~/types/common/reportMainEntityFileUrl';
import { ReportMainEntityFileUrlDTO } from '~/types/dto/common/reportMainEntityFileUrlDTO';
import { ReportMarker } from '~/types/common/reportMarker';
import { ReportMarkerDTO } from '~/types/dto/common/reportMarkerDTO';
import { ReportPersonInn } from '~/types/common/reportPersonInn';
import { ReportPersonInnDTO } from '~/types/dto/common/reportPersonInnDTO';
import { ReportPersonSnilsDTO } from '~/types/dto/common/reportPersonSnilsDTO';
import { ReportPersonSnils } from '~/types/common/reportPersonSnils';
import { ReportPassportDTO } from '~/types/dto/common/reportPassportDTO';
import { ReportPassport } from '~/types/common/reportPassport';
import { ReportBoundedPersonPhoneDTO } from '~/types/dto/common/boundedPersonPhoneDTO';
import { ReportBoundedPersonPhone } from '~/types/common/reportBoundedPersonPhone';
import { ReportTagDTO } from '~/types/dto/common/reportTagDTO';
import { ReportTag } from '~/types/common/reportTag';
import { ReportEmailDTO } from '~/types/dto/common/emailDTO';
import { ReportEmail } from '~/types/common/reportEmail';
import { ReportPersonSiteDTO } from '~/types/dto/common/reportPersonSiteDTO';
import { ReportPersonSite } from '~/types/common/reportPersonSite';
import { ReportSocialNetworkIdDTO } from '~/types/dto/common/reportSocialNetworkIdDTO';
import { ReportSocialNetworkId } from '~/types/common/reportSocialNetworkId';
import { ReportSocialNetworkTrackDTO } from '~/types/dto/common/reportSocialNetworkTrackDTO';
import { ReportSocialNetworkTrack } from '~/types/common/reportSocialNetworkTrack';
import { ReportSocialNetworkSchool } from '~/types/common/reportSocialNetworkSchool';
import { ReportSocialNetworkSchoolDTO } from '~/types/dto/common/reportSocialNetworkSchoolDTO';
import { ReportSocialNetworkSecondaryEducation } from '~/types/common/reportSocialNetworkSecondaryEducation';
import { ReportSocialNetworkSecondaryEducationDTO } from '~/types/dto/common/reportSocialNetworkSecondaryEducationDTO';
import { ReportSocialNetworkHighEducationDTO } from '~/types/dto/common/reportSocialNetworkHighEducationDTO';
import { ReportSocialNetworkHighEducation } from '~/types/common/reportSocialNetworkHighEducation';
import { ReportSocialNetworkMilitaryRecordDTO } from '~/types/dto/common/reportSocialNetworkMilitaryRecordDTO';
import { ReportSocialNetworkMilitaryRecord } from '~/types/common/reportSocialNetworkMilitaryRecord';
import { ReportSocialNetworkJob } from '~/types/common/reportSocialNetworkJob';
import { ReportSocialNetworkJobDTO } from '~/types/dto/common/reportSocialNetworkJobDTO';
import { ReportConnectionRelated } from '~/types/common/reportConnectionRelated';
import { ReportConnectionRelatedDTO } from '~/types/dto/common/reportConnectionRelatedDTO';
import { ReportConnectionRelatedEntity } from '~/types/common/reportConnectionRelatedEntity';
import { ReportConnectionRelatedEntityDTO } from '~/types/dto/common/reportConnectionRelatedEntityDTO';
import { ReportConnectionRelatedEntityDisplay } from '~/types/common/reportConnectionRelatedEntityDisplay';
import { ReportConnectionRelatedEntityDisplayDTO } from '~/types/dto/common/reportConnectionRelatedEntityDisplayDTO';
import { ReportConnectionRelatedRelation } from '~/types/common/reportConnectionRelatedRelation';
import { ReportConnectionRelatedRelationDTO } from '~/types/dto/common/reportConnectionRelatedRelationDTO';
import { ReportAddress } from '~/types/common/address';
import { ReportAddressDTO } from '~/types/dto/common/addressDTO';
import { ReportVehicleParking } from '~/types/common/reportVehicleParking';
import { ReportVehicleParkingDTO } from '~/types/dto/common/vehicleParkingDTO';
import { ReportMentionDTO } from '~/types/dto/common/reportMentionDTO';
import { ReportMention } from '~/types/common/reportMention';
import { ReportEducationDTO } from '~/types/dto/common/reportEducationDTO';
import { ReportEducation } from '~/types/common/reportEducation';
import { ReportMessengerInfo } from '~/types/common/reportMessengerInfo';
import { ReportMessengerInfoDTO } from '~/types/dto/common/reportMessengerInfoDTO';
import { ReportJobDTO } from '~/types/dto/common/reportJobDTO';
import { ReportJob } from '~/types/common/reportJob';
import { ReportPaymentDTO } from '~/types/dto/common/reportPaymentDTO';
import { ReportPayment } from '~/types/common/reportPayment';
import { ReportColleague } from '~/types/common/reportColleague';
import { ReportColleagueDTO } from '~/types/dto/common/reportColleagueDTO';
import { ReportIndividualEntrepreneur } from '~/types/common/reportIndividualEntrepreneur';
import { ReportIndividualEntrepreneurDTO } from '~/types/dto/common/reportIndividualEntrepreneurDTO';
import { ReportFineDTO } from '~/types/dto/common/fineDTO';
import { ReportFine } from '~/types/common/reportFine';
import { ReportPossibleFullName } from '~/types/common/reportPossibleFullName';
import { ReportPossibleFullNameDTO } from '~/types/dto/common/reportPossibleFullNameDTO';
import { ReportPossibleBirthdaysDTO } from '~/types/dto/common/reportPossibleBirthdaysDTO';
import { ReportPossibleBirthdays } from '~/types/common/reportPossibleBirthdays';
import { ReportMovementCovidDTO } from '~/types/dto/common/reportMovementCovidDTO';
import { ReportMovementCovid } from '~/types/common/reportMovementCovid';
import { ReportMovementTaxiDTO } from '~/types/dto/common/reportMovementTaxiDTO';
import { ReportMovementTaxi } from '~/types/common/reportMovementTaxi';
import { ReportMessageBoardRecordDTO } from '~/types/dto/common/messageBoardsDTO';
import { ReportMessageBoardRecord } from '~/types/common/reportMessageBoardRecord';
import { ReportSocialNetworkLifePositionDTO } from '~/types/dto/common/reportSocialNetworkLifePositionDTO';
import { ReportSocialNetworkLifePosition } from '~/types/common/reportSocialNetworkLifePosition';
import { ReportSocialNetwork } from '~/types/common/reportSocialNetwork';
import { ReportSocialNetworkAboutInfo } from '~/types/common/reportSocialNetworkAboutInfo';
import { ReportSocialNetworkOtherPerson } from '~/types/common/reportSocialNetworkOtherPerson';
import { ReportSocialNetworkAboutInfoDTO } from '~/types/dto/common/reportSocialNetworkAboutInfoDTO';
import { ReportSocialNetworkDTO } from '~/types/dto/common/reportSocialNetworkDTO';
import { ReportSocialNetworkOtherPersonDTO } from '~/types/dto/common/reportSocialNetworkOtherPersonDTO';
import { ReportVehicleRisks } from '~/types/common/reportVehicleRisks';
import { ReportVehicleRisksDTO } from '~/types/dto/common/reportVehicleRisksDTO';

export type IReportUrlAdapterService = IAdapterService<ReportUrl, ReportUrlDTO>;
export type IReportArrayStringAdapterService = IAdapterService<Array<string>, Array<string>>;
export type IReportMainEntityFileAdapterService = IAdapterService<ReportMainEntityFile, ReportMainEntityFileDTO>;
export type IReportLegalEntitiesParticipationAdapterService = IAdapterService<ReportLegalEntitiesParticipation[], ReportLegalEntitiesParticipationDTO[]>
export type IReportVehicleAdapterService = IAdapterService<ReportVehicle[], ReportVehicleDTO[]>
export type IReportVehicleOwnershipAdapterService = IAdapterService<ReportVehicleOwnership[], ReportVehicleOwnershipDTO[]>;
export type IReportVehicleRelationAdapterService = IAdapterService<ReportVehicleRelation[], ReportVehicleRelationDTO[]>;
export type IReportVehicleInsuranceAdapterService = IAdapterService<ReportVehicleInsurance[], ReportVehicleInsuranceDTO[]>;
export type IReportRoadAccidentAdapterService = IAdapterService<ReportRoadAccident[], ReportRoadAccidentDTO[]>;
export type IReportVehicleRestrictAdapterService = IAdapterService<ReportVehicleRestrict[], ReportVehicleRestrictDTO[]>;
export type IReportPledgesRegistryAdapterService = IAdapterService<ReportPledgesRegistry[], ReportPledgesRegistryDTO[]>;
export type IReportWantedCarAdapterService = IAdapterService<ReportWantedCar[], ReportWantedCarDTO[]>;
export type IReportPhotosUrlDTOAdapterService = IAdapterService<ReportPhotosUrl[], ReportPhotosUrlDTO[]>;
export type IReportMainEntityFileUrlAdapterService = IAdapterService<ReportMainEntityFileUrl, ReportMainEntityFileUrlDTO>;
export type IReportMarkerAdapterService = IAdapterService<ReportMarker[], ReportMarkerDTO[]>;
export type IReportPersonInnAdapterService = IAdapterService<ReportPersonInn[], ReportPersonInnDTO[]>
export type IReportConnectionRelatedAdapterService = IAdapterService<ReportConnectionRelated, ReportConnectionRelatedDTO[]>
export type IReportConnectionRelatedEntitiesAdapterService = IAdapterService<ReportConnectionRelatedEntity[], ReportConnectionRelatedEntityDTO[]>
export type IReportConnectionRelatedEntityDisplayAdapterService = IAdapterService<ReportConnectionRelatedEntityDisplay[], ReportConnectionRelatedEntityDisplayDTO[]>
export type IReportConnectionRelatedRelationsAdapterService = IAdapterService<ReportConnectionRelatedRelation[], ReportConnectionRelatedRelationDTO[]>
export type IReportPersonSnilsAdapterService = IAdapterService<ReportPersonSnils[], ReportPersonSnilsDTO[]>
export type IReportPassportAdapterService = IAdapterService<ReportPassport[], ReportPassportDTO[]>
export type IReportBoundedPersonPhoneAdapterService = IAdapterService<ReportBoundedPersonPhone[], ReportBoundedPersonPhoneDTO[]>
export type IReportTagAdapterService = IAdapterService<ReportTag[], ReportTagDTO[]>
export type IReportEmailAdapterService = IAdapterService<ReportEmail[], ReportEmailDTO[]>
export type IReportPersonSiteAdapterService = IAdapterService<ReportPersonSite[], ReportPersonSiteDTO[]>
export type IReportSocialNetworkIdAdapterService = IAdapterService<ReportSocialNetworkId[], ReportSocialNetworkIdDTO[]>
export type IReportSocialNetworkTrackAdapterService = IAdapterService<ReportSocialNetworkTrack, ReportSocialNetworkTrackDTO>
export type IReportSocialNetworkSchoolAdapterService = IAdapterService<ReportSocialNetworkSchool[], ReportSocialNetworkSchoolDTO[]>
export type IReportSocialNetworkSecondaryEducationAdapterService = IAdapterService<ReportSocialNetworkSecondaryEducation[], ReportSocialNetworkSecondaryEducationDTO[]>
export type IReportSocialNetworkHighEducationAdapterService = IAdapterService<ReportSocialNetworkHighEducation[], ReportSocialNetworkHighEducationDTO[]>
export type IReportSocialNetworkMilitaryRecordAdapterService = IAdapterService<ReportSocialNetworkMilitaryRecord[], ReportSocialNetworkMilitaryRecordDTO[]>
export type IReportSocialNetworkJobAdapterService = IAdapterService<ReportSocialNetworkJob[], ReportSocialNetworkJobDTO[]>
export type IReportAddressAdapterService = IAdapterService<ReportAddress[], ReportAddressDTO[]>
export type IReportVehicleParkingAdapterService = IAdapterService<ReportVehicleParking[], ReportVehicleParkingDTO[]>;
export type IReportMentionAdapterService = IAdapterService<ReportMention[], ReportMentionDTO[]>
export type IReportEducationAdapterService = IAdapterService<ReportEducation[], ReportEducationDTO[]>
export type IReportMessengerInfoAdapterService = IAdapterService<ReportMessengerInfo[], ReportMessengerInfoDTO[]>
export type IReportJobAdapterService = IAdapterService<ReportJob[], ReportJobDTO[]>
export type IReportPaymentAdapterService = IAdapterService<ReportPayment[], ReportPaymentDTO[]>
export type IReportColleagueAdapterService = IAdapterService<ReportColleague[], ReportColleagueDTO[]>
export type IReportIndividualEntrepreneurAdapterService = IAdapterService<ReportIndividualEntrepreneur[], ReportIndividualEntrepreneurDTO[]>
export type IReportFineAdapterService = IAdapterService<ReportFine[], ReportFineDTO[]>
export type IReportPossibleFullNameAdapterService = IAdapterService<ReportPossibleFullName[], ReportPossibleFullNameDTO[]>;
export type IReportPossibleBirthdaysAdapterService = IAdapterService<ReportPossibleBirthdays[], ReportPossibleBirthdaysDTO[]>
export type IReportMovementCovidAdapterService = IAdapterService<ReportMovementCovid[], ReportMovementCovidDTO[]>
export type IReportMovementTaxiAdapterService = IAdapterService<ReportMovementTaxi[], ReportMovementTaxiDTO[]>
export type IReportMessageBoardRecordAdapterService = IAdapterService<ReportMessageBoardRecord[], ReportMessageBoardRecordDTO[]>
export type IReportSocialNetworkLifePositionAdapterService = IAdapterService<ReportSocialNetworkLifePosition, ReportSocialNetworkLifePositionDTO>
export type IReportSocialNetworkAdapterService = IAdapterService<ReportSocialNetwork[], ReportSocialNetworkDTO[]>
export type IReportSocialNetworkOtherPersonAdapterService = IAdapterService<ReportSocialNetworkOtherPerson[], ReportSocialNetworkOtherPersonDTO[]>
export type IReportSocialNetworkAboutInfoAdapterService = IAdapterService<ReportSocialNetworkAboutInfo, ReportSocialNetworkAboutInfoDTO>
export type IReportVehicleRisksAdapterService = IAdapterService<ReportVehicleRisks, ReportVehicleRisksDTO>;

export const IReportMessageBoardRecordAdapterServiceKey: InjectionKey<IReportMessageBoardRecordAdapterService> = Symbol('IReportMessageBoardRecordAdapterService');
export const IReportMovementTaxiAdapterServiceKey: InjectionKey<IReportMovementTaxiAdapterService> = Symbol('IReportMovementTaxiAdapterService');
export const IReportMovementCovidAdapterServiceKey: InjectionKey<IReportMovementCovidAdapterService> = Symbol('IReportMovementCovidAdapterService');
export const IReportPossibleBirthdaysAdapterServiceKey: InjectionKey<IReportPossibleBirthdaysAdapterService> = Symbol('IReportPossibleBirthdaysAdapterService');
export const IReportPossibleFullNameAdapterServiceKey: InjectionKey<IReportPossibleFullNameAdapterService> = Symbol('IReportPossibleFullNameAdapterService');
export const IReportFineAdapterServiceKey: InjectionKey<IReportFineAdapterService> = Symbol('IReportFineAdapterService');
export const IReportMessengerInfoAdapterServiceKey: InjectionKey<IReportMessengerInfoAdapterService> = Symbol('IReportMessengerInfoAdapterService');
export const IReportColleagueAdapterServiceKey: InjectionKey<IReportColleagueAdapterService> = Symbol('IReportColleagueAdapterService');
export const IReportPaymentAdapterServiceKey: InjectionKey<IReportPaymentAdapterService> = Symbol('IReportPaymentAdapterService');
export const IReportJobAdapterServiceKey: InjectionKey<IReportJobAdapterService> = Symbol('IReportJobAdapterService');
export const IReportEducationAdapterServiceKey: InjectionKey<IReportEducationAdapterService> = Symbol('IReportEducationAdapterService');
export const IReportMentionAdapterServiceKey: InjectionKey<IReportMentionAdapterService> = Symbol('IReportMentionAdapterService');
export const IReportVehicleParkingAdapterServiceKey: InjectionKey<IReportVehicleParkingAdapterService> = Symbol('IReportVehicleParkingAdapterService');
export const IReportAddressAdapterServiceKey: InjectionKey<IReportAddressAdapterService> = Symbol('IIndividualAddressAdapterService');
export const IReportSocialNetworkJobAdapterServiceKey: InjectionKey<IReportSocialNetworkJobAdapterService> = Symbol('IReportSocialNetworkJobAdapterService');
export const IReportSocialNetworkMilitaryRecordAdapterServiceKey: InjectionKey<IReportSocialNetworkMilitaryRecordAdapterService> = Symbol('IReportSocialNetworkMilitaryRecordAdapterService');
export const IReportSocialNetworkHighEducationAdapterServiceKey: InjectionKey<IReportSocialNetworkHighEducationAdapterService> = Symbol('IReportSocialNetworkHighEducationAdapterService');
export const IReportSocialNetworkSecondaryEducationAdapterServiceKey: InjectionKey<IReportSocialNetworkSecondaryEducationAdapterService> = Symbol('IReportSocialNetworkSecondaryEducationAdapterService');
export const IReportSocialNetworkSchoolAdapterServiceKey: InjectionKey<IReportSocialNetworkSchoolAdapterService> = Symbol('IReportSocialNetworkSchoolAdapterService');
export const IReportSocialNetworkTrackAdapterServiceKey: InjectionKey<IReportSocialNetworkTrackAdapterService> = Symbol('IReportSocialNetworkTrackAdapterService');
export const IReportSocialNetworkIdAdapterServiceKey: InjectionKey<IReportSocialNetworkIdAdapterService> = Symbol('IReportSocialNetworkIdAdapterService');
export const IReportPersonSiteAdapterServiceKey: InjectionKey<IReportPersonSiteAdapterService> = Symbol('IReportPersonSiteAdapterService');
export const IReportEmailAdapterServiceKey: InjectionKey<IReportEmailAdapterService> = Symbol('IReportEmailAdapterService');
export const IReportTagAdapterServiceKey: InjectionKey<IReportTagAdapterService> = Symbol('IReportTagAdapterService');
export const IReportBoundedPersonPhoneAdapterServiceKey: InjectionKey<IReportBoundedPersonPhoneAdapterService> = Symbol('IReportBoundedPersonPhoneAdapterService');
export const IReportPassportAdapterServiceKey: InjectionKey<IReportPassportAdapterService> = Symbol('IReportPassportAdapterService');
export const IReportPersonSnilsAdapterServiceKey: InjectionKey<IReportPersonSnilsAdapterService> = Symbol('IReportPersonSnilsAdapterService');
export const IReportPersonInnAdapterServiceKey: InjectionKey<IReportPersonInnAdapterService> = Symbol('IReportPersonInnAdapterService');
export const IReportMarkerAdapterServiceKey: InjectionKey<IReportMarkerAdapterService> = Symbol('IReportMarkerAdapterService');
export const IReportVehicleAdapterServiceKey: InjectionKey<IReportVehicleAdapterService> = Symbol('IReportVehicleAdapterService');
export const IReportLegalEntitiesParticipationAdapterServiceKey: InjectionKey<IReportLegalEntitiesParticipationAdapterService> = Symbol('IReportLegalEntitiesParticipationAdapterService');
export const IReportUrlAdapterServiceKey: InjectionKey<IReportUrlAdapterService> = Symbol('IReportUrlAdapterService');
export const IReportArrayStringAdapterServiceKey: InjectionKey<IReportArrayStringAdapterService> = Symbol('IReportArrayStringAdapterService');
export const IReportMainEntityFileAdapterServiceKey: InjectionKey<IReportMainEntityFileAdapterService> = Symbol('IReportMainEntityFileAdapterService');
export const IReportVehicleOwnershipAdapterServiceKey: InjectionKey<IReportVehicleOwnershipAdapterService> = Symbol('IReportVehicleOwnershipAdapterService');
export const IReportVehicleRelationAdapterServiceKey: InjectionKey<IReportVehicleRelationAdapterService> = Symbol('IReportVehicleRelationAdapterService');
export const IReportVehicleInsuranceAdapterServiceKey: InjectionKey<IReportVehicleInsuranceAdapterService> = Symbol('IReportVehicleInsuranceAdapterService');
export const IReportRoadAccidentAdapterServiceKey: InjectionKey<IReportRoadAccidentAdapterService> = Symbol('IReportRoadAccidentAdapterService');
export const IReportVehicleRestrictAdapterServiceKey: InjectionKey<IReportVehicleRestrictAdapterService> = Symbol('IReportVehicleRestrictAdapterService');
export const IReportPledgesRegistryAdapterServiceKey: InjectionKey<IReportPledgesRegistryAdapterService> = Symbol('IReportPledgesRegistryAdapterService');
export const IReportWantedCarAdapterServiceKey: InjectionKey<IReportWantedCarAdapterService> = Symbol('IReportWantedCarAdapterService');
export const IReportPhotosUrlDTOAdapterServiceKey: InjectionKey<IReportPhotosUrlDTOAdapterService> = Symbol('IReportPhotosUrlDTOAdapterService');
export const IReportMainEntityFileUrlAdapterServiceKey: InjectionKey<IReportMainEntityFileUrlAdapterService> = Symbol('IReportMainEntityFileUrlAdapterService');
export const IReportConnectionRelatedAdapterServiceKey: InjectionKey<IReportConnectionRelatedAdapterService> = Symbol('IReportConnectionRelatedAdapterService');
export const IReportConnectionRelatedEntitiesAdapterServiceKey: InjectionKey<IReportConnectionRelatedEntitiesAdapterService> = Symbol('IReportConnectionRelatedEntitiesAdapterService');
export const IReportConnectionRelatedEntityDisplayAdapterServiceKey: InjectionKey<IReportConnectionRelatedEntityDisplayAdapterService> = Symbol('IReportConnectionRelatedEntityDisplayAdapterService');
export const IReportConnectionRelatedRelationsAdapterServiceKey: InjectionKey<IReportConnectionRelatedRelationsAdapterService> = Symbol('IReportConnectionRelatedRelationsAdapterService');
export const IReportIndividualEntrepreneurAdapterServiceKey: InjectionKey<IReportIndividualEntrepreneurAdapterService> = Symbol('IReportIndividualEntrepreneureAdapterService');
export const IReportSocialNetworkLifePositionAdapterServiceKey: InjectionKey<IReportSocialNetworkLifePositionAdapterService> = Symbol('IReportSocialNetworkLifePositionAdapterService');
export const IReportSocialNetworkAboutInfoAdapterServiceKey: InjectionKey<IReportSocialNetworkAboutInfoAdapterService> = Symbol('IReportSocialNetworkAboutInfoAdapterService');
export const IReportSocialNetworkOtherPersonAdapterServiceKey: InjectionKey<IReportSocialNetworkOtherPersonAdapterService> = Symbol('IReportSocialNetworkOtherPersonAdapterService');
export const IReportSocialNetworkAdapterServiceKey: InjectionKey<IReportSocialNetworkAdapterService> = Symbol('IReportSocialNetworkAdapterService');
export const IReportVehicleRisksAdapterServiceKey: InjectionKey<IReportVehicleRisksAdapterService> = Symbol('IReportVehicleRisksAdapterService');