import { injectable } from 'inversify';
import { DateUtils } from '~/utils/date';
import {
  LegalEntitiesCompanyHeadFlWithIsFirstDateType
} from '~/modules/legalEntities/changeHistory/LegalEntitiesAuthorityToSignChangeHistory/types/legalEntitiesCompanyHeadFlWithIsFirstDateType';
import { Config } from '~/types/field-list/config';
import {
  ILegalEntitiesAuthorityToSignConfigService
} from '~/modules/legalEntities/changeHistory/LegalEntitiesAuthorityToSignChangeHistory/services/legalEntitiesAuthorityToSignConfigService.interfaces';

@injectable()
export class LegalEntitiesAuthorityToSignConfigService implements ILegalEntitiesAuthorityToSignConfigService {
  getConfig(initData: LegalEntitiesCompanyHeadFlWithIsFirstDateType): Config {
    const description = initData.IsInaccuracy ? 'недостоверные сведения в ЕГРЮЛ' : '';
    const label = initData.isFirstDate ? 'Дата внесения сведений' : 'Дата внесения изменений';

    const config: Config = {
      fieldsDirection: 'divider-row',
      fieldsWidth: '100%',
      fieldsValueWidth: '350px',
      isBeginningDivider: true,
      fields: []
    };

    config.fields.push(
      {
        label: initData.Position || 'Руководитель',
        key: 'Fio',
        value: initData.Fio,
        isInner: true
      },
      {
        label: 'ИНН',
        key: 'Innfl',
        value: initData.Innfl,
      },
    );

    if (
      initData.FirstDate !== initData.Date
        && initData.FirstDate
        && initData.Date
    ) {
      config.fields.push(
        {
          label: 'Дата первого внесения сведений',
          key: 'FirstDate',
          value: DateUtils.formatDate(initData.FirstDate),
        },
        {
          label: 'Дата последнего внесения сведений',
          key: 'LastDate',
          value: {
            value: DateUtils.formatDate(initData.Date),
            description,
          },
        }
      );
    } else {
      config.fields.push(
        {
          label,
          key: 'OnlyFirstDate',
          value: {
            value: DateUtils.formatDate(initData.FirstDate),
            description,
          },
        }
      );
    }

    return config;
  }
}
