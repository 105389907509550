export module CommonUtils {
  export function isReliable(value: string): boolean {
    return !value.toLowerCase().includes('недостоверный');
  }

  /**
   * @deprecated
   */
  export function removeDuplicates<T>(arr: T[], key?: keyof T): T[] {
    const uniqueIds = new Set();

    if (!key) {
      return Array.from(new Set(arr));
    }

    return arr.filter((item) => {
      const id = item[key];

      if (uniqueIds.has(id)) {
        return false;
      }

      uniqueIds.add(id);

      return true;
    });
  }

  export function getRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  export function getRandomArrayElement<T>(array: T[]): T {
    const index = getRandomNumber(0, array.length - 1);
    return array[index];
  }

  /**
   * @deprecated
   */
  export function replaceEmptyFields<T>(input: T): T {
    if (input === null || input === undefined || input === '') {
      return '—' as any;
    }
    if (typeof input === 'object') {
      for (const key in input) {
        if (input.hasOwnProperty(key)) {
          input[key] = replaceEmptyFields(input[key]);
        }
      }
    }
    return input;
  }

  export function formatName(name: string) {
    const splitName = name.toLowerCase().split(' ');

    return splitName
      .map((name) => {
        const newName = name.split('');

        if (!newName.length) return '';

        newName[0] = newName[0].toUpperCase();

        return newName.join('');
      })
      .join(' ').trim();
  }

  export function removeDuplicateObjects<T>(
    array: T[],
    isEqual: (a: any, b: any) => boolean
  ) {
    return array.filter(
      (item, index, self) => self.findIndex((i) => isEqual(i, item)) === index
    );
  }

  export function convertToRub(str: string) {
    const variants = [
      'рубль',
      'рубля',
      'рублей',
      'р.',
      'руб.',
      'рубль.',
      'рублёв',
      'рублёв.'
    ];

    if (variants.includes(str.toLowerCase())) {
      return 'руб.';
    }

    return str;
  }

  /**
   * @deprecated
   */
  export function mergeArrayElementsWithObject<T, K extends keyof T>(
    sourceObject: T,
    key: K
  ) {
    const arr: T[K][] = [];

    const sourceObjectArray = sourceObject[key] as any[];

    if (Array.isArray(sourceObjectArray)) {
      sourceObjectArray.forEach((element: T[K]) => arr.push({ ...element, ...sourceObject }));
    }

    return arr as T & T[K];
  }
}
