import { injectable, inject } from 'inversify';

import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { ReportPledgesRegistry } from '~/types/common/reportPledgesRegistry';
import { ReportRoadAccident } from '~/types/common/reportRoadAccident';
import { ReportVehicle } from '~/types/common/reportVehicle';
import { ReportVehicleInsurance } from '~/types/common/reportVehicleInsurance';
import { ReportVehicleOwnership } from '~/types/common/reportVehicleOwnership';
import { ReportVehiclePhone } from '~/types/common/reportVehiclePhone';
import { ReportPhotosUrl } from '~/types/common/reportVehiclePhotosUrl';
import { ReportVehicleRelation } from '~/types/common/reportVehicleRelation';
import { ReportVehicleRestrict } from '~/types/common/reportVehicleRestrict';
import { ReportVehicleRisks } from '~/types/common/reportVehicleRisks';
import { ReportWantedCar } from '~/types/common/reportWantedCar';
import { INormalizationService,
  IReportPhotosUrlNormalizationServiceKey,
  IReportPledgesRegistryNormalizationServiceKey,
  IReportRoadAccidentNormalizationServiceKey,
  IReportVehicleInsuranceNormalizationServiceKey,
  IReportVehicleOwnershipNormalizationServiceKey,
  IReportVehiclePhoneNormalizationServiceKey,
  IReportVehicleRelationNormalizationServiceKey,
  IReportVehicleRestrictNormalizationServiceKey,
  IReportVehicleRisksNormalizationServiceKey,
  IReportWantedCarNormalizationServiceKey } from './reportNormalizationService.interfaces';
import { IReportVehicleRisksPlaceholderServiceKey, IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';

@injectable()
export class ReportVehicleNormalizationService implements INormalizationService<ReportVehicle> {
  constructor(
    @inject(IReportVehicleOwnershipNormalizationServiceKey as symbol) private reportVehicleOwnershipNormalizationService: INormalizationService<ReportVehicleOwnership>,
    @inject(IReportVehicleRelationNormalizationServiceKey as symbol) private reportVehicleRelationNormalizationService: INormalizationService<ReportVehicleRelation>,
    @inject(IReportVehicleInsuranceNormalizationServiceKey as symbol) private reportVehicleInsuranceNormalizationService: INormalizationService<ReportVehicleInsurance>,
    @inject(IReportPhotosUrlNormalizationServiceKey as symbol) private reportPhotosUrlNormalizationService: INormalizationService<ReportPhotosUrl>,
    @inject(IReportVehicleRestrictNormalizationServiceKey as symbol) private reportVehicleRestrictNormalizationService: INormalizationService<ReportVehicleRestrict>,
    @inject(IReportRoadAccidentNormalizationServiceKey as symbol) private reportRoadAccidentNormalizationService: INormalizationService<ReportRoadAccident>,
    @inject(IReportWantedCarNormalizationServiceKey as symbol) private reportWantedCarNormalizationService: INormalizationService<ReportWantedCar>,
    @inject(IReportVehiclePhoneNormalizationServiceKey as symbol) private reportVehiclePhoneNormalizationService: INormalizationService<ReportVehiclePhone>,
    @inject(IReportPledgesRegistryNormalizationServiceKey as symbol) private reportPledgesRegistryNormalizationService: INormalizationService<ReportPledgesRegistry>,
    @inject(IReportVehicleRisksNormalizationServiceKey as symbol) private reportVehicleRisksNormalizationService: INormalizationService<ReportVehicleRisks>,

    @inject(IReportVehicleRisksPlaceholderServiceKey as symbol) private reportVehicleRisksPlaceholderService: IPlaceholderDataService<ReportVehicleRisks>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportVehicle | undefined {
    const {
      normalizeString,
      normalizeOptionalString,
      normalizeOptionalInteger,
      normalizeNumber,
      normalizeArray,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      CarNumber: normalizeOptionalString(data.CarNumber, `${root}.CarNumber`),
      Vin: normalizeOptionalString(data.Vin, `${root}.Vin`),
      Brand: normalizeOptionalString(data.Brand, `${root}.Brand`),
      Model: normalizeOptionalString(data.Model, `${root}.Model`),
      BrandSourceValue: normalizeString(data.BrandSourceValue, `${root}.BrandSourceValue`),
      ModelSourceValue: normalizeString(data.ModelSourceValue, `${root}.ModelSourceValue`),
      VerificationCode: normalizeNumber(data.VerificationCode, `${root}.VerificationCode`),
      Year: normalizeOptionalInteger(data.Year, `${root}.VerificationCode`),
      Color: normalizeOptionalString(data.Color, `${root}.Color`),
      OwnersCount: normalizeOptionalInteger(data.OwnersCount, `${root}.VerificationCode`),
      OwnerShipPeriod: normalizeOptionalInteger(data.OwnerShipPeriod, `${root}.VerificationCode`),
      OwnershipHistory: normalizeArray(data.OwnershipHistory, (item, field) => this.reportVehicleOwnershipNormalizationService.normalize(item, field), `${root}.OwnershipHistory`),
      Relations: normalizeArray(data.Relations, (item, field) => this.reportVehicleRelationNormalizationService.normalize(item, field), `${root}.Relations`),
      VehicleInsurances: normalizeArray(data.VehicleInsurances, (item, field) => this.reportVehicleInsuranceNormalizationService.normalize(item, field), `${root}.VehicleInsurances`),
      PhotoUrls: normalizeArray(data.PhotoUrls, (item, field) => this.reportPhotosUrlNormalizationService.normalize(item, field), `${root}.PhotoUrls`),
      VehicleRestricts: normalizeArray(data.VehicleRestricts, (item, field) => this.reportVehicleRestrictNormalizationService.normalize(item, field), `${root}.VehicleRestricts`),
      RoadAccidents: normalizeArray(data.RoadAccidents, (item, field) => this.reportRoadAccidentNormalizationService.normalize(item, field), `${root}.RoadAccidents`),
      Wanted: normalizeArray(data.Wanted, (item, field) => this.reportWantedCarNormalizationService.normalize(item, field), `${root}.Wanted`),
      VehiclePhones: normalizeArray(data.VehiclePhones, (item, field) => this.reportVehiclePhoneNormalizationService.normalize(item, field), `${root}.VehiclePhones`),
      PledgesRegistries: normalizeArray(data.PledgesRegistries, (item, field) => this.reportPledgesRegistryNormalizationService.normalize(item, field), `${root}.PledgesRegistries`),
      Risks: this.reportVehicleRisksNormalizationService.normalize(data.Risks, `${root}.Risks`) ?? this.reportVehicleRisksPlaceholderService.getData()
    }), root);
  }
}
