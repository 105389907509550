import {
  DataBlobReportType,
  UIReportType,
  APIReportType,
} from '~/types/reportType';
import { reportRoutes, reportRoutesFromAPI, reportAPIFromRoutes } from '~/configuration/reportRoutes';
import { ViewMode } from '~/types/viewMode';

export module ReportUtils {
  export function getReportRouteNameByType(type: APIReportType) {
    switch (type) {
      case APIReportType.carNumber: return 'report-vehicle';
      case APIReportType.company: return 'report-legal-entities';
      case APIReportType.person: return 'report-individual';
      case APIReportType.phoneNumber: return 'report-phone-number';
      case APIReportType.connection: return 'report-graph';
      default: return '';
    }
  }

  export function getReportRouteTypeByName(
    name: string
  ): APIReportType {
    switch (name) {
      case 'report-vehicle': return APIReportType.carNumber;
      case 'report-legal-entities': return APIReportType.company;
      case 'report-individual': return APIReportType.person;
      case 'report-phone-number': return APIReportType.phoneNumber;
      default: return APIReportType.unknown;
    }
  }

  export function getCurrentUIReportType(): UIReportType | undefined {
    const route = useRoute();

    const routeEntry = Object.entries(reportRoutes).find(
      ([key, value]) => value === route.path
    );

    if (routeEntry != undefined) return <UIReportType>routeEntry[0];
  }

  export function formatAPIReportType(reportType?: APIReportType) {
    switch (reportType) {
      case APIReportType.person:
        return 'Отчет по персоне';
      case APIReportType.company:
        return 'Отчет по компании/ИП';
      case APIReportType.phoneNumber:
        return 'Отчет по абонентскому номеру';
      case APIReportType.carNumber:
        return 'Отчет по госномеру транспортного средства';
      default:
        return 'Неизвестно';
    }
  }
  export function getReportRouteFromAPIEnum(reportType: string) {
    return reportRoutesFromAPI[reportType];
  }

  export function gerReportTypeByRoute(route: string): APIReportType {
    return reportAPIFromRoutes[route];
  }

  export function getAPIReportTypeFromDataBlobReportType(
    reportType: DataBlobReportType
  ) {
    switch (reportType) {
      case DataBlobReportType.carNumber:
        return APIReportType.carNumber;
      case DataBlobReportType.phoneNumber:
        return APIReportType.phoneNumber;
      case DataBlobReportType.company:
        return APIReportType.company;
      case DataBlobReportType.person:
        return APIReportType.person;
      case DataBlobReportType.unknown:
        return APIReportType.unknown;
    }
  }

  export function getReportRoute(reportType: UIReportType) {
    return reportRoutes[reportType];
  }

  export function parseViewModeByQuery(queryType: string): ViewMode {
    const lowerQueryType = queryType.toLowerCase();
  
    switch (lowerQueryType) {
      case 'pdf':
        return ViewMode.pdf;
      default:
        return ViewMode.web;
    }
  }
}
