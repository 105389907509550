import { injectable, inject } from 'inversify';
import { LegalEntitiesConditionAssessment } from '~/types/legal-entities/conditionAssessment';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';
import { LegalEntitiesCompanyTaxRegimes } from '~/types/legal-entities/companyTaxRegimes';

@injectable()
export class LegalEntitiesCompanyTaxRegimesNormalizationService implements INormalizationService<LegalEntitiesCompanyTaxRegimes> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService
  ) {}

  normalize(data: any, root: string): LegalEntitiesCompanyTaxRegimes | undefined {
    const { normalizeObject, normalizeOptionalString, normalizeOptionalInteger, normalizeBoolean } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      isHistoric: normalizeBoolean(data.isHistoric, `${root}.isHistoric`),
      kgnMember: normalizeOptionalString(data.kgnMember, `${root}.kgnMember`),
      taxRegimeName: normalizeOptionalString(data.taxRegimeName, `${root}.taxRegimeName`),
      year: normalizeOptionalInteger(data.year, `${root}.year`)
    }), root);
  }
}
