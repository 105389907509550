import { StringMap } from '~/types/stringMap';

export module URLUtils {
    const queryPattern = /\?[^#]+/;

    export function getQueryArgs(url: string): StringMap<string> {
      const match = queryPattern.exec(url);

      if (match != undefined) {
        const query = match[0];

        return query.split('&').reduce((result, item) => {
          const [key, rawValue] = item.split('=');
          const value = decodeURIComponent(rawValue.replace(/\+/g, ' '));

          if (key != undefined && value != undefined) { result[key] = value; }

          return result;
        }, <StringMap<string>>{});
      }

      return {};
    }

    export function isUrl(url: string) {
      return url.includes('://');
    }

    export function getUrlWithoutProtocol(url: string) {
      try {
        const { protocol } = new URL(url);
        return url.replace(`${protocol}//`, '');
      } catch {
        return '';
      }
    }

    export function getHostName(url: string) {
      try {
        const { hostname } = new URL(url);

        return hostname;
      } catch {
        return '';
      }
    }
}
