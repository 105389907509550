import { uniqWith } from 'lodash-es';
import { StringUtils } from '~/utils/stringUtils';
import { ListUtils } from '~/utils/listUtils';

import { ReportConnectionRelatedDTO } from '~/types/dto/common/reportConnectionRelatedDTO';
import { ReportConnectionRelatedRelationDTO } from '~/types/dto/common/reportConnectionRelatedRelationDTO';
import { ConnectionItemInfo } from '~/modules/reportsCommon/reportConnection/types/connectionItemInfo';

function entitiesComparator(l: string, r: string) {
  return (l === '' && r === '') || StringUtils.isEqualStrings(l, r);
}

function relationsComparator(l: ReportConnectionRelatedRelationDTO, r: ReportConnectionRelatedRelationDTO) {
  const relationMatch = StringUtils.isEqualStrings(l.DisplayName, r.DisplayName) && l.Type === r.Type;

  return l.DisplayName === '' && r.DisplayName === '' || relationMatch;
}

export module ConnectionsUtils {
  export function getUniqConnections(connections: ReportConnectionRelatedDTO[]): ReportConnectionRelatedDTO[] {
    return uniqWith(connections, (arrVal, othVal) => {
      const conEntNames = arrVal.Entities.map((entity) => entity.Name);
      const othConEntNames = othVal.Entities.map((entity) => entity.Name);

      const entitiesIsEqual = ListUtils.compareLists(conEntNames, othConEntNames, entitiesComparator);
      const relationsIsEqual = ListUtils.compareLists(arrVal.Relations, othVal.Relations, relationsComparator);

      return entitiesIsEqual && relationsIsEqual;
    });
  }

  export function getRelationPerson(connections: Array<ConnectionItemInfo>, connectionIdx: number) {
    const connectionItem = connections[connectionIdx];

    return function (entitiesIdx: number) {
      return connectionItem.Entities[entitiesIdx];
    };
  }

  export function calculationCommunicationLevel(value: number): number {
    return Math.round(value / 2);
  }

  export function communicationLevel(relationDepth: number): string {
    switch (relationDepth) {
      case 1:
        return 'Близкая связь';
      case 2:
        return 'Средняя связь';
      case 3:
        return 'Дальняя связь';
      default:
        return '';
    }
  }
}
