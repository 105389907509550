import 'reflect-metadata';
import { Container } from 'inversify';
import { InjectionKey } from 'vue';
import { bindSplittableServices } from '~/configuration/inversify/container.splittableServices';
import { IDataServiceKey } from '~/services/data-service.interface';
import { DataService } from '~/services/data-service';
import { nuxtEndpointKey, useNuxtEndpoint } from '@/endpoints/useNuxtEndpoint';
import { APIEndpointKey, useAPIEndpoint } from '@/endpoints/useAPIEndpoint';
import { APIAsyncService } from '@/services/api-async-service';
import { IAPIAsyncServiceKey } from '@/services/api-async-service.interface';
import GraphService from '~/services/graphService';
import { IGraphServiceKey } from '~/services/graphService.interfaces';
import { bindNormalizationServices } from './container.normalizationServices';
import { bindPlaceholderServices } from './container.placeholderServices';
import { bindAdapterServices } from './container.adapterServices';
import { bindMergeServices } from './container.mergeServices';
import { ITagServiceKey } from '~/services/tagService.interfaces';
import { TagService } from '~/services/tagService';
import { IMocksServiceKey } from '~/services/mocks-service.interfaces';
import { MocksService } from '@/services/mocks-service';
import { IPaginationServiceKey } from '~/services/paginations/pagination-service.interfaces';
import { PaginationService } from '~/services/paginations/pagination-service';
import { bindMatchingServices } from '~/configuration/inversify/container.matchingServices';
import { bindLegalEntitiesServices } from '~/components/legalEntities/configuration/inversify/container.legalEntitiesServices';
import { bindConfigServices } from '~/configuration/inversify/container.configService';
import { bindModuleServices } from '~/configuration/inversify/container.moduleServices';
import { bindPhoneNumberAdapterServices } from '~/configuration/inversify/container.phoneNumberAdapterServices';
import { bindVehiclesAdapterServices } from '~/configuration/inversify/container.vehiclesAdapterServices';
import { ITasksServiceKey } from '~/services/tasks-service.interfaces';
import { TasksService } from '~/services/tasks-service';
import { IReportsServiceKey } from '~/services/reports-service.interfaces';
import { ReportsService } from '~/services/reports-service';

export function getContainer() {
  const container = new Container();

  function bind<T>(key: InjectionKey<T>) {
    return container.bind<T>(key as symbol);
  }

  bindNormalizationServices(bind);
  bindAdapterServices(bind);
  bindMergeServices(bind);
  bindPlaceholderServices(bind);
  bindSplittableServices(bind);
  bindMatchingServices(bind);
  bindLegalEntitiesServices(bind);
  bindConfigServices(bind);
  bindModuleServices(bind);
  bindPhoneNumberAdapterServices(bind);
  bindVehiclesAdapterServices(bind);

  bind(IDataServiceKey).to(DataService);
  bind(IAPIAsyncServiceKey).to(APIAsyncService);
  bind(nuxtEndpointKey).toDynamicValue(() => useNuxtEndpoint()).inSingletonScope();
  bind(APIEndpointKey).toDynamicValue(() => useAPIEndpoint()).inSingletonScope();
  bind(ITagServiceKey).to(TagService);
  bind(IMocksServiceKey).to(MocksService);
  bind(IPaginationServiceKey).to(PaginationService);
  bind(IGraphServiceKey).to(GraphService);
  bind(ITasksServiceKey).to(TasksService);
  bind(IReportsServiceKey).to(ReportsService);

  return container;
}
