/* eslint-disable class-methods-use-this, max-len */

import { injectable, inject } from 'inversify';
import { ReportVehicle } from '~/types/common/reportVehicle';
import { ReportVehicleDTO } from '~/types/dto/common/reportVehicleDTO';
import { StringUtils } from '~/utils/stringUtils';

import {
  IReportPhotosUrlDTOAdapterService,
  IReportPhotosUrlDTOAdapterServiceKey,
  IReportPledgesRegistryAdapterService,
  IReportPledgesRegistryAdapterServiceKey,
  IReportRoadAccidentAdapterService,
  IReportRoadAccidentAdapterServiceKey,
  IReportVehicleAdapterService,
  IReportVehicleInsuranceAdapterService,
  IReportVehicleInsuranceAdapterServiceKey,
  IReportVehicleOwnershipAdapterService,
  IReportVehicleOwnershipAdapterServiceKey,
  IReportVehicleRelationAdapterService,
  IReportVehicleRelationAdapterServiceKey,
  IReportVehicleRestrictAdapterService,
  IReportVehicleRestrictAdapterServiceKey,
  IReportVehicleRisksAdapterService,
  IReportVehicleRisksAdapterServiceKey,
  IReportWantedCarAdapterService,
  IReportWantedCarAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';

@injectable()
export class ReportVehicleAdapterService implements IReportVehicleAdapterService {
  constructor(
    @inject(IReportPhotosUrlDTOAdapterServiceKey as symbol) private reportPhotosUrlDTOAdapterService: IReportPhotosUrlDTOAdapterService,
    @inject(IReportVehicleRestrictAdapterServiceKey as symbol) private reportVehicleRestrictAdapterService: IReportVehicleRestrictAdapterService,
    @inject(IReportWantedCarAdapterServiceKey as symbol) private reportWantedCarAdapterService: IReportWantedCarAdapterService,
    @inject(IReportPledgesRegistryAdapterServiceKey as symbol) private reportPledgesRegistryAdapterService: IReportPledgesRegistryAdapterService,
    @inject(IReportRoadAccidentAdapterServiceKey as symbol) private reportRoadAccidentAdapterService: IReportRoadAccidentAdapterService,
    @inject(IReportVehicleInsuranceAdapterServiceKey as symbol) private reportVehicleInsuranceAdapterService: IReportVehicleInsuranceAdapterService,
    @inject(IReportVehicleOwnershipAdapterServiceKey as symbol) private reportVehicleOwnershipAdapterService: IReportVehicleOwnershipAdapterService,
    @inject(IReportVehicleRelationAdapterServiceKey as symbol) private reportVehicleRelationAdapterService: IReportVehicleRelationAdapterService,
    @inject(IReportVehicleRisksAdapterServiceKey as symbol) private reportVehicleRisksAdapterService: IReportVehicleRisksAdapterService
  ) {
  }
  getData(initData: ReportVehicle[]): ReportVehicleDTO[] {
    return (initData ?? []).map((item) => {
      const carNumber = StringUtils.removeEscapeCharacters(item.CarNumber ?? '');

      return {
        Vin: item.Vin ?? '',
        FullCarNumber: carNumber,
        CarNumber: carNumber.slice(0, 6),
        CarNumberRegion: carNumber.slice(6),
        Model: item.Model ?? '',
        Brand: item.Brand ?? '',
        Color: StringUtils.quoteReplacement(item.Color ?? ''),
        OwnersCount: item.OwnersCount ?? 0,
        Year: item.Year ?? 0,
        OwnerShipPeriod: item.OwnerShipPeriod ?? 0,
        PhotoUrls: this.reportPhotosUrlDTOAdapterService.getData(item.PhotoUrls),
        Restricts: this.reportVehicleRestrictAdapterService.getData(item.VehicleRestricts),
        Wanted: this.reportWantedCarAdapterService.getData(item.Wanted),
        PledgesRegistries: this.reportPledgesRegistryAdapterService.getData(item.PledgesRegistries),
        RoadAccidents: this.reportRoadAccidentAdapterService.getData(item.RoadAccidents),
        VehicleInsurances: this.reportVehicleInsuranceAdapterService.getData(item.VehicleInsurances),
        OwnershipHistory: this.reportVehicleOwnershipAdapterService.getData(item.OwnershipHistory),
        Relations: this.reportVehicleRelationAdapterService.getData(item.Relations),
        Risks: this.reportVehicleRisksAdapterService.getData(item.Risks),
      }; 
    });
  }
} 
