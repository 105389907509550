/* eslint-disable class-methods-use-this, max-len */
import { injectable } from 'inversify';
import { LegalEntitiesCompanyTaxRegimesDTO } from '~/types/dto/legalEntities/companyTaxRegimesDTO';
import { LegalEntitiesCompanyTaxRegimes } from '~/types/legal-entities/companyTaxRegimes';
import { ILegalEntitiesCompanyTaxRegimesAdapterService } from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';

@injectable()
export class LegalEntitiesCompanyTaxRegimesAdapterService implements ILegalEntitiesCompanyTaxRegimesAdapterService {
  getData(initData: LegalEntitiesCompanyTaxRegimes[]): LegalEntitiesCompanyTaxRegimesDTO[] {
    return (initData ?? []).map((companyTax) => ({
      IsHistoric: companyTax.isHistoric,
      KgnMember: companyTax.kgnMember ?? '',
      TaxRegimeName: companyTax.taxRegimeName ?? '',
      Year: companyTax.year ?? 0
    }));
  }
}
