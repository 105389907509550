import { injectable } from 'inversify';
import { IReportVehicleParkingTableConfigService } from '~/modules/reportsCommon/reportVehicleParking/services/reportVehicleParkingTableConfigService.interface';
import { ReportVehicleParkingDTO } from '~/types/dto/common/vehicleParkingDTO';
import { TableItems } from '~/types/tableItems';
import { DateUtils } from '~/utils/date';
import { ReportVehicleParking } from '~/modules/reportsCommon/reportVehicleParking/utils/reportVehicleParkingUtils';

@injectable()
export class ReportVehicleParkingTableConfigService implements IReportVehicleParkingTableConfigService {
  getHeaders() {
    return [
      {
        label: 'Телефон',
        key: 'phone',
      },
      {
        label: 'Гос.номер',
        key: 'carNumber',
      },
      {
        label: 'Парковка',
        key: 'placeCode',
      },
      {
        label: 'Дата',
        key: 'date',
      },
      {
        label: 'Время',
        key: 'time',
      },
      {
        label: 'Длительность',
        key: 'duration',
      },
    ];
  }

  getItems(initData: ReportVehicleParkingDTO[]): TableItems {
    return initData.map((item) => {
      const time = DateUtils.formatTimeToHoursAndMinutes(item.StartDate);
      const duration = item.Duration ? ReportVehicleParking.selectingDate(item.Duration) : '—';
      return {
        phone: item.Phone || '—',
        carNumber: item.CarNumber || '—',
        placeCode: `№${item.PlaceCode}` || '—',
        date: DateUtils.formatDate(item.StartDate) || '—',
        time: time || '—',
        duration: duration,
      };
    });
  }
}
