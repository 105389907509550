import { injectable } from 'inversify';
import { IMetaDataAdapterService } from './metaDataAdapterService.interface';
import { DataBlobMetadataDTO } from '~/types/dto/common/dataBlobMetadata';
import { GenericDataBlob } from '~/types/blobs/generic-data-blob';

@injectable()
export class MetaDataAdapterService implements IMetaDataAdapterService {
  getData(dataBlob: GenericDataBlob | undefined): DataBlobMetadataDTO {
    return {
      InputReportType: dataBlob?.OriginalInput?.InputReportType ?? 0,
      ReportGenerationDateTime: dataBlob?.ReportGenerationDateTime ?? ''
    };
  }
}
