import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { bindLegalEntitiesModulesServices } from '~/modules/legalEntities/common/configurations/inversify/container.legalEntitiesModulesServices';
import { bindIndividualModulesServices } from '~/modules/individual/common/configurations/inversify/container.individualModulesServices';
import { bindReportsCommonModulesServices } from '~/modules/reportsCommon/common/configurations/inversify/container.reportsCommonModulesServices';

export function bindModuleServices(bind: IContainerBinder) {
  bindLegalEntitiesModulesServices(bind);
  bindIndividualModulesServices(bind);
  bindReportsCommonModulesServices(bind);
}
