import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import {
  LegalEntitiesAuthorityToSignConfigService
} from '~/modules/legalEntities/changeHistory/LegalEntitiesAuthorityToSignChangeHistory/services/legalEntitiesAuthorityToSignConfigService';
import {
  ILegalEntitiesAuthorityToSignMergeServiceKey
} from '~/modules/legalEntities/changeHistory/LegalEntitiesAuthorityToSignChangeHistory/services/legalEntitiesAuthorityToSignMergeService.interfaces';
import {
  LegalEntitiesAuthorityToSignMergeService
} from '~/modules/legalEntities/changeHistory/LegalEntitiesAuthorityToSignChangeHistory/services/legalEntitiesAuthorityToSignMergeService';
import {
  ILegalEntitiesAuthorityToSignConfigServiceKey
} from '~/modules/legalEntities/changeHistory/LegalEntitiesAuthorityToSignChangeHistory/services/legalEntitiesAuthorityToSignConfigService.interfaces';

export function bindLegalEntitiesAuthorityToSignServices(bind: IContainerBinder) {
  bind(ILegalEntitiesAuthorityToSignConfigServiceKey).to(LegalEntitiesAuthorityToSignConfigService);
  bind(ILegalEntitiesAuthorityToSignMergeServiceKey).to(LegalEntitiesAuthorityToSignMergeService);
}
