import { injectable } from 'inversify';
import { Config } from '~/types/field-list/config';
import { ReportSocialNetworkLifePositionDTO } from '~/types/dto/common/reportSocialNetworkLifePositionDTO';
import { FIELD_LIST_SOCIAL_OPTIONS } from '../../configuration/constants';
import { IReportSocialNetworkLifePositionConfigService } from './reportSocialNetworkConfigService.interfaces';

@injectable()
export class ReportSocialNetworkLifePositionConfigService implements IReportSocialNetworkLifePositionConfigService {
  getConfig(socialItem: ReportSocialNetworkLifePositionDTO): Config {
    return {
      ...FIELD_LIST_SOCIAL_OPTIONS,
      fields: [
        {
          key: 'PoliticalPreferences',
          label: 'Политические предпочтения',
          value: socialItem.PoliticalPreferences,
        },
        {
          key: 'WorldView',
          label: 'Мировоззрение',
          value: socialItem.WorldView,
        },
        {
          key: 'MainThingInLife',
          label: 'Главное в жизни',
          value: socialItem.MainThingInLife,
        },
        {
          key: 'MainThingInPeople',
          label: 'Главное в людях',
          value: socialItem.MainThingInPeople,
        },
        {
          key: 'SmokingView',
          label: 'Отношение к курению',
          value: socialItem.SmokingView,
        },
        {
          key: 'AlcoholView',
          label: 'Отношение к алкоголю',
          value: socialItem.AlcoholView,
        },
        {
          key: 'Inspires',
          label: 'Вдохновляют',
          value: socialItem.Inspires,
        },
      ].filter(item => item.value)
    };
  }
}
