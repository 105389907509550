/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { StringUtils } from '~/utils/stringUtils';

import { LegalEntitiesTaxesPenalties } from '~/types/legal-entities/taxesPenalties';
import {
  ILegalEntitiesTaxesPenaltiesAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesTaxesPenaltiesDTO } from '~/types/dto/legalEntities/taxesPenaltiesDTO';

@injectable()
export class LegalEntitiesTaxesPenaltiesAdapterService implements ILegalEntitiesTaxesPenaltiesAdapterService {
  getData(initData: LegalEntitiesTaxesPenalties[]): LegalEntitiesTaxesPenaltiesDTO[] {
    return (initData ?? []).map((penalties) => ({
      name: StringUtils.quoteReplacement(penalties.name),
      sum: penalties.sum,
      year: penalties.year
    }));
  }
}
