import { injectable } from 'inversify';
import { IReportParkedBoundedPersonPhonesConfigService } from './reportParkedBoundedPersonPhonesConfigService.interface';
import { Config } from '~/types/field-list/config';
import { ReportParkedBoundedPhoneDTO } from '~/modules/reportsCommon/reportParkedBoundedPersonPhones/common/types/reportParkedBoundedPhone';

@injectable()
export class ReportParkedBoundedPersonPhonesConfigService implements IReportParkedBoundedPersonPhonesConfigService {
  getConfig(initData: ReportParkedBoundedPhoneDTO): Config {
    return {
      fieldsDirection: 'divider-row',
      fieldsWidth: '100%',
      fieldsValueWidth: '350px',
      isBeginningDivider: true,
      fields: [
        {
          key: 'FullName',
          label: 'ФИО',
          value: initData.FullName
        },
        {
          key: 'Birthday',
          label: 'Дата рождения',
          value: initData.RelatedBirth
        },
        {
          key: 'Address',
          label: 'Адрес',
          value: initData.RelatedAddress
        },
      ]
    };
  }
}
