import { injectable } from 'inversify';
import { MergeService } from '~/services/merge/merge-service';
import { LegalEntitiesCompanyHeadFlDTO } from '~/types/dto/legalEntities/companyHeadFlDTO';
import { DateUtils } from '~/utils/date';

@injectable()
export class LegalEntitiesAuthorityToSignMergeService extends MergeService<LegalEntitiesCompanyHeadFlDTO> {
  protected canMerge(leftItem: LegalEntitiesCompanyHeadFlDTO, rightItem: LegalEntitiesCompanyHeadFlDTO): boolean {
    const isFioEqual = leftItem.Fio === rightItem.Fio;
    const isInnEqual = leftItem.Innfl === rightItem.Innfl;
    const isPositionEqual = leftItem.Position === rightItem.Position;

    const isMergeByPosition = isFioEqual && isInnEqual && (leftItem.Position.length !== 0 || rightItem.Position.length !== 0);
    const isMergeByInn = isFioEqual && isPositionEqual && (leftItem.Innfl.length !== 0 || rightItem.Innfl.length !== 0);
    const isMergeByFio = isInnEqual && isPositionEqual && (leftItem.Fio.length !== 0 || rightItem.Fio.length !== 0);

    const isMergeByPositionAndInn = isFioEqual && (leftItem.Innfl.length !== 0 || rightItem.Innfl.length !== 0)
        && (leftItem.Position.length !== 0 || rightItem.Position.length !== 0);
    const isMergeByPositionAndFio = isInnEqual && (leftItem.Fio.length !== 0 || rightItem.Fio.length !== 0)
        && (leftItem.Position.length !== 0 || rightItem.Position.length !== 0);

    const isFioUnknown = leftItem.Fio.length !== 0 && rightItem.Fio.length !== 0;
    const isInnUnknown = leftItem.Innfl.length !== 0 && rightItem.Innfl.length !== 0;
    const isPositionUnknown = leftItem.Position.length !== 0 && rightItem.Position.length !== 0;

    return (isFioEqual && isInnEqual && isPositionEqual)
        || isMergeByPosition || isMergeByInn || isMergeByFio
        || isMergeByPositionAndInn || isMergeByPositionAndFio
        || (isFioUnknown && (isInnEqual || isPositionEqual))
        || (isInnUnknown && (isFioEqual || isPositionEqual))
        || (isPositionUnknown && (isFioEqual || isInnEqual));
  }

  protected mergeFields(leftItem: LegalEntitiesCompanyHeadFlDTO, rightItem: LegalEntitiesCompanyHeadFlDTO): LegalEntitiesCompanyHeadFlDTO {
    let firstDate: string;
    let lastDate: string;

    if (leftItem.FirstDate && rightItem.FirstDate) {
      const firstDateDiff = DateUtils
        .calcDateDiff(
          DateUtils.parseDate(leftItem.FirstDate),
          DateUtils.parseDate(rightItem.FirstDate),
          ['days']
        ).days;
      firstDate = firstDateDiff && firstDateDiff > 0 ? leftItem.FirstDate : rightItem.FirstDate;
    } else firstDate = leftItem.FirstDate || rightItem.FirstDate;

    if (leftItem.Date && rightItem.Date) {
      const lastDateDiff = DateUtils
        .calcDateDiff(
          DateUtils.parseDate(leftItem.Date),
          DateUtils.parseDate(rightItem.Date),
          ['days']
        ).days;
      lastDate = lastDateDiff && lastDateDiff > 0 ? rightItem.Date : leftItem.Date;
    } else lastDate = leftItem.Date || rightItem.Date;

    return {
      Fio: leftItem.Fio || rightItem.Fio,
      Innfl: leftItem.Innfl || rightItem.Innfl,
      Position: leftItem.Position || rightItem.Position,
      Date: lastDate,
      FirstDate: firstDate,
      IsInaccuracy: leftItem.IsInaccuracy || rightItem.IsInaccuracy,
      InaccuracyDate: leftItem.InaccuracyDate || rightItem.InaccuracyDate,
      IsCompanyCoOwner: leftItem.IsCompanyCoOwner || rightItem.IsCompanyCoOwner,
      OwnershipPercent: leftItem.OwnershipPercent || rightItem.OwnershipPercent
    };
  }
}
