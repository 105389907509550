import { AxiosInstance } from 'axios'
import { APIReportType } from "~/types/reportType";
import { IReportsService } from "./reports-service.interfaces";
import { ReportGenerationTask } from "~/types/report/report";
import { injectable, inject } from "inversify";
import { APIEndpointKey } from "~/endpoints/useAPIEndpoint";
import { useTasksEvents } from '@/composables/useTasksEvents';

@injectable()
export class ReportsService implements IReportsService {
  private readonly BASE_URL_V2 = '/api/v2/async-pdf/tasks';
  private readonly BASE_URL_V3 = '/api/v3/async-pdf';

  constructor(@inject(APIEndpointKey as symbol) private api: AxiosInstance) {}

  async addReportsToList(report: File[]): Promise<void> {
    const formData = new FormData();

    const tasksEvents = useTasksEvents();

    report.forEach((item) => formData.append('filesToHandle', item));

    const response = await this.api.post<ReportGenerationTask[]>(
      `${this.BASE_URL_V3}/tasks/generate-from-multiple-files`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    tasksEvents.tasksCreated.emit(response.data);
  }

  async generateReport(reportType: APIReportType, report: File): Promise<void> {

    const tasksEvents = useTasksEvents();

    const formData = new FormData();
    formData.append('jsonFile', report);

    const response = await this.api.post<ReportGenerationTask>(
      `${this.BASE_URL_V2}/generate-from-file`,
      formData,
      {
        params: {
          reportType,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    tasksEvents.taskCreated.emit(response.data);
  }

  async generateReportManually(reportTakId: string): Promise<ReportGenerationTask> {
    const response = await this.api.post(`${this.BASE_URL_V3}/generation/${reportTakId}`);

    return response.data;
  }
}