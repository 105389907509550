import { injectable, inject } from 'inversify';
import { orderBy } from 'lodash-es';
import {
  IReportMessageBoardRecordAdapterService, IReportUrlAdapterService,
  IReportUrlAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportMessageBoardRecord } from '~/types/common/reportMessageBoardRecord';
import { ReportMessageBoardRecordDTO } from '~/types/dto/common/messageBoardsDTO';
import { StringUtils } from '~/utils/stringUtils';
import { DateUtils } from '~/utils/date';
import { PhoneUtils } from '~/utils/phone';
import { NumberUtils } from '~/utils/number';

@injectable()
export class ReportMessageBoardRecordAdapterService implements IReportMessageBoardRecordAdapterService {
  constructor(
    @inject(IReportUrlAdapterServiceKey as symbol) private reportUrlAdapterService: IReportUrlAdapterService
  ) {}
  getData(initData: ReportMessageBoardRecord[]): ReportMessageBoardRecordDTO[] {
    
    return orderBy(initData ?? [], ['RegistrationDate'], ['desc']).map((item) => {
      const sourceCategory = item.Category ?? '';
      const category = StringUtils.quoteReplacement(StringUtils.CapitalizeString(sourceCategory.replaceAll(/\,([а-яА-Я])/g, ', $1').toLowerCase()));
      
      return {
        MessageSourceType: StringUtils.quoteReplacement(item.MessageSourceType),
        MessageBoardDataType: item.MessageBoardDataType,
        Category: category,
        MessageTitle: StringUtils.quoteReplacement(item.MessageTitle ?? ''),
        MessageText: StringUtils.quoteReplacement(item.MessageText ?? ''),
        RegistrationDate: DateUtils.formatDate(item.RegistrationDate),
        Region: StringUtils.quoteReplacement(item.Region),
        Price: NumberUtils.formatNumberDigits(item.Price ?? 0, 0, ''),
        Currency: StringUtils.quoteReplacement(item.Currency),
        Year: item.Year ?? 0,
        CarNumber: StringUtils.quoteReplacement(item.CarNumber ?? ''),
        Brand: StringUtils.quoteReplacement(item.Brand ?? ''),
        Model: StringUtils.quoteReplacement(item.Model ?? ''),
        Vin: StringUtils.quoteReplacement(item.Vin ?? ''),
        Contactface: StringUtils.quoteReplacement(item.Contactface ?? ''),
        RequestNumber: PhoneUtils.getIsValidPhoneNumber(item.RequestNumber ?? '') ? PhoneUtils.formatPhoneNumberWithDash(item.RequestNumber ?? '') : '',
        Link: this.reportUrlAdapterService.getData(item.Link)
      }
    });
  }
}
