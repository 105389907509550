import { injectable, inject } from 'inversify';
import {
  ILegalEntitiesReportFinancialIndicatorWithConclusionsAdapterService,
  ILegalEntitiesReportFinancialIndicatorWithConclusionsAdapterServiceKey,
  ILegalEntitiesReportFinancialIndicatorWithDynamicAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesReportFinancialIndicatorWithDynamic } from '~/types/legal-entities/reportFinancialIndicatorWithDynamic';
import { LegalEntitiesReportFinancialIndicatorWithDynamicDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorWithDynamicDTO';

@injectable()
export class LegalEntitiesReportFinancialIndicatorWithDynamicAdapterService implements ILegalEntitiesReportFinancialIndicatorWithDynamicAdapterService {
  constructor(
    @inject(ILegalEntitiesReportFinancialIndicatorWithConclusionsAdapterServiceKey as symbol) private legalEntitiesReportFinancialIndicatorWithConclusionsAdapterService: ILegalEntitiesReportFinancialIndicatorWithConclusionsAdapterService,
  ) {}
  getData(initData: LegalEntitiesReportFinancialIndicatorWithDynamic): LegalEntitiesReportFinancialIndicatorWithDynamicDTO {
    const legalEntitiesReportFinancialIndicatorWithConclusions = this.legalEntitiesReportFinancialIndicatorWithConclusionsAdapterService.getData(initData);
    return {
      TwoYearsAgoToLastYear: initData.TwoYearsAgoToLastYear ?? 0,
      LastYearToCurrentYear: initData.LastYearToCurrentYear ?? 0,
      ...legalEntitiesReportFinancialIndicatorWithConclusions
    };
  }
}
