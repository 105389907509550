import { IS_VALID_PTS_REGEX } from '~/regex/isValidPts';
import { FORMAT_PTS_REGEX } from '~/regex/formatPts';

export module PtsUtils {
  export function getIsValidPts(inputString: string): boolean {
    return IS_VALID_PTS_REGEX.test(inputString);
  }

  export function formattedPts(inputString: string): string {
    return inputString.replace(FORMAT_PTS_REGEX, '$1 $2');
  }
}
