import { injectable } from 'inversify';
import { IReportPersonSnilsConfigServices } from '~/modules/reportsCommon/reportPersonSnils/services/reportPersonSnilsConfigServices.interfaces';
import { ReportPersonSnilsDTO } from '~/types/dto/common/reportPersonSnilsDTO';
import { Config } from '~/types/field-list/config';
import { ReportPersonSnilsUtils } from '~/modules/reportsCommon/reportPersonSnils/utils/reportPersonSnilsUtils';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class ReportPersonSnilsConfigServices implements IReportPersonSnilsConfigServices {
  getConfig(initData: ReportPersonSnilsDTO): Config {
    return {
      fieldsWidth: '100%',
      fieldsDirection: 'divider-row',
      fieldsValueWidth: '350px',
      isBeginningDivider: true,
      fields: [
        {
          key: 'snils',
          label: initData.Value,
          value: StringUtils.isStringNotEmpty(initData.CorrespondingPerson)
            ? { value: 'Принадлежит персоне:', description: ReportPersonSnilsUtils.formattingDateInString(initData.CorrespondingPerson) }
            : undefined
        }]
    };
  }
}
