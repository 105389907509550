import { injectable } from 'inversify';
import { Config } from '~/types/field-list/config';
import { ILegalEntitiesTaxModeConfigService } from './legalEntitiesTaxModeConfigService.interfaces';
import { LegalEntitiesTaxModeDTO } from '~/types/dto/legalEntities/taxMode';
import { LegalEntitiesUtils } from '~/modules/legalEntities/common/utils/legalEntitiesUtils';

@injectable()
export class LegalEntitiesTaxModeConfigService implements ILegalEntitiesTaxModeConfigService {
  getConfig(initData: LegalEntitiesTaxModeDTO): Config {
    const replacedTaxMode = LegalEntitiesUtils.replaceTaxText(initData.Name.toLowerCase());
    
    return {
      fieldsDirection: 'divider-row',
      fieldsWidth: '100%',
      fieldsValueWidth: '350px',
      isBeginningDivider: true,
      fields: [
        {
          label: 'Тип',
          key: 'replacedTaxMode', 
          value: replacedTaxMode
        }
      ]
    };
  }
}
