import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { bindLegalEntitiesGeneralInformationService } from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/configurations/inversify/container.legalEntitiesGeneralInformation';
import { bindLegalEntitiesCompanyFounderServices } from '~/modules/legalEntities/common/legalEntitiesCompanyFounder/configurations/inversify/container.legalEntitiesCompanyFounderServices';
import { bindLegalEntitiesTaxModeServices } from '~/modules/legalEntities/commonInfo/legalEntitiesTaxMode/configuration/inversify/container.legalEntitiesTaxModeServices';
import { bindLegalEntitiesCompanyPhoneService } from '~/modules/legalEntities/commonInfo/legalEntitiesPhoneNumbers/configurations/inversify/container.legalEntitiesCompanyPhone';
import { bindLegalEntitiesChangeHistoryModulesServices } from '~/modules/legalEntities/changeHistory/common/configuration/inversify/container.legalEntitiesChangeHistoryModulesServices';
import { bindLegalEntitiesRelatedEgrulService } from '~/modules/legalEntities/connection/legalEntitiesRelatedEgrul/configurations/container.legalEntitiesRelatedEgrul';
import { bindLegalEntitiesClaimsAndDebtsService } from '~/modules/legalEntities/commonInfo/legalEntitiesClaimsAndDebts/configurations/inversify/container.legalEntitiesClaimsAndDebts';
import { bindLegalEntitiesContractsInfoByYear } from '~/modules/legalEntities/companyActivities/legalEntitiesStateProcurements/configurations/inversify/container.legalEntitiesContractsInfoByYear';
import { bindLegalEntitiesInformationCreditOrganizationsService } from '~/modules/legalEntities/registrationData/legalEntitiesInformationCreditOrganizations/configurations/inversify/container.legalEntitiesInformationCreditOrganizations';

export function bindLegalEntitiesModulesServices(bind: IContainerBinder) {
  bindLegalEntitiesGeneralInformationService(bind);
  bindLegalEntitiesCompanyFounderServices(bind);
  bindLegalEntitiesTaxModeServices(bind);
  bindLegalEntitiesCompanyPhoneService(bind);
  bindLegalEntitiesChangeHistoryModulesServices(bind);
  bindLegalEntitiesRelatedEgrulService(bind);
  bindLegalEntitiesClaimsAndDebtsService(bind);
  bindLegalEntitiesContractsInfoByYear(bind);
  bindLegalEntitiesInformationCreditOrganizationsService(bind);
}
