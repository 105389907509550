import { inject, injectable } from 'inversify';
import { orderBy } from 'lodash-es';
import {
  ILegalEntitiesCompanyTaxRegimesTableConfigService
} from '~/modules/legalEntities/changeHistory/legalEntitiesCompanyTaxRegimes/services/legalEntitiesTaxRegimesTableConfigService.interface';
import { LegalEntitiesUtils } from '~/modules/legalEntities/common/utils/legalEntitiesUtils';
import { LegalEntitiesCompanyTaxRegimesDTO } from '~/types/dto/legalEntities/companyTaxRegimesDTO';
import {
  ILegalEntitiesCompanyTaxRegimesMergeService,
  ILegalEntitiesCompanyTaxRegimesMergeServiceKey
} from '~/modules/legalEntities/changeHistory/legalEntitiesCompanyTaxRegimes/services/legalEntitiesTaxRegimesMergeService.interface';

@injectable()
export class LegalEntitiesCompanyTaxRegimesTableConfigService implements ILegalEntitiesCompanyTaxRegimesTableConfigService {
  constructor(
    @inject(ILegalEntitiesCompanyTaxRegimesMergeServiceKey as symbol) private legalEntitiesCompanyTaxRegimesMergeService: ILegalEntitiesCompanyTaxRegimesMergeService,
  ) {}
  getHeaders() {
    return [
      {
        label: 'Год',
        width: '84px',
        key: 'year'
      },
      {
        label: 'Налоговый режим',
        key: 'taxMode'
      },
    ];
  }

  getItems(initData: LegalEntitiesCompanyTaxRegimesDTO[]) {
    const mergeTaxModeData = this.legalEntitiesCompanyTaxRegimesMergeService.getMergeTaxRegimes(initData);

    const sortedDataTaxRegime = orderBy(mergeTaxModeData, ['Year'], ['desc']);

    return sortedDataTaxRegime.map((item) => ({
      year: item.Year,
      taxMode: LegalEntitiesUtils.replaceTaxText(item.TaxRegimeName),
    }));
  }
}
