import { DateUtils } from '~/utils/date';

export module ReportPersonSnilsUtils {
  export function formattingDateInString(value: string) :string {
    const regexp = /,/g;

    const formatString = value.replace(regexp, ', ');

    const splitValue = formatString.split(' ');

    splitValue.forEach((item, index) => {
      const date = Date.parse(item);
      if (!Number.isNaN(date)) {
        splitValue[index] = DateUtils.formatDate(item);
      }
    });

    return splitValue.join(' ');
  }
}
