import { computed, ref, onMounted } from 'vue';
import { sortBy, get } from 'lodash-es';
import useIndividualStore from '@/stores/individual/individualStore';
import useLegalEntitiesStoreStore from '~/stores/legalEntities/legalEntitiesStore';
import useVehicleVehiclesStore from '@/stores/vehicle/vehiclesStore';
import { useReportType } from '~/hooks/useReportType';
import usePhoneNumberStore from '~/stores/phone-number/phoneNumberStore';
import { ReportVehicleCarNumberSourceType } from '~/types/common/reportVehicleCarNumberSourceType';
import { APIReportType } from '~/types/reportType';
import { PhoneUtils } from '~/utils/phone';
import { StringUtils } from '~/utils/stringUtils';

const DEFAULT_TITLE = 'Sweep net';

export function useReportCommonInfo() {
  const isMounted = ref(false);

  onMounted(() => {
    isMounted.value = true;
  });

  return computed(() => {

    if (!isMounted.value) return DEFAULT_TITLE;

    const reportType = useReportType();
  
    if (reportType.value === APIReportType.carNumber) {
      const vehiclesStore = useVehicleVehiclesStore();

      const ownedVehicle = get(vehiclesStore.value.Vehicles, 0, undefined);
      
      if (ownedVehicle !== undefined) {
        return `${ownedVehicle.CarNumber} ${ownedVehicle.CarNumberRegion}`;
      }

      return DEFAULT_TITLE;
    }

    if (reportType.value === APIReportType.phoneNumber) {
      const phoneNumberStore = usePhoneNumberStore();

      return PhoneUtils.formatPhoneNumber(phoneNumberStore.value.phoneNumberData.Common.PhoneNumber);
    }

    if (reportType.value === APIReportType.person) {
      const individualStore = useIndividualStore();

      return individualStore.value.Common.FullName;
    }

    if (reportType.value === APIReportType.company) {
      const companiesStore = useLegalEntitiesStoreStore();
      
      let companies = [...(companiesStore.value.companies ?? [])];
  
      companies = sortBy(companies, [
        (company) => -(new Date(company.briefInfo.CompanyRegistrationDate ?? ''))
      ]);

      const companyWithName = companies.find((company) => StringUtils.isStringNotEmpty(company.commonInfo.CompanyShortName));

      if (companyWithName !== undefined) {
        return companyWithName.commonInfo.CompanyShortName;
      }

      return DEFAULT_TITLE;
    }

    return DEFAULT_TITLE;
  });
}
