export module ReportVehicleParking {
  export function selectingDate(dateString: string): string {
    if (!dateString.includes('.')) return dateString;

    const dateParts = dateString.split('.');
    const days = dateParts[0];
    const time = dateParts[1];

    return `${days}дн - ${time}`;
  }
}
