import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { ReportVehicleRelationGeneralConfigService } from '~/modules/reportsCommon/reportVehicle/services/reportVehicleGeneralRelationConfig';
import { IReportVehicleRelationGeneralConfigServiceKey } from '~/modules/reportsCommon/reportVehicle/services/vehicleConfigService.interfaces';
import { OwnerInfoConfigService } from '~/modules/vehicles/common/services/ownerInfoConfigService';
import { IOwnerInfoConfigServiceKey } from '~/modules/vehicles/common/services/ownerInfoConfigService.interfaces';

export function bindVehiclesConfigServices(bind: IContainerBinder) {
  bind(IReportVehicleRelationGeneralConfigServiceKey).to(ReportVehicleRelationGeneralConfigService);
  bind(IOwnerInfoConfigServiceKey).to(OwnerInfoConfigService);
}
