import { IContainerBinder } from './containerAccessor.interface';
import { IReportVettingInfoAdapterServiceKey } from '~/services/adapters/reportAdapterService.interface';
import { ReportVettingInfoAdapterService } from '~/services/adapters/common/reportVettingInfoAdapterService';
import { ReportVehicleParkingAdapterService } from '~/services/adapters/common/reportVehicleParkingAdapterService';
import { ReportUrlAdapterService } from '~/services/adapters/common/reportUrlAdapterService';
import {
  IReportArrayStringAdapterServiceKey,
  IReportBoundedPersonPhoneAdapterServiceKey,
  IReportEmailAdapterServiceKey,
  IReportConnectionRelatedAdapterServiceKey,
  IReportLegalEntitiesParticipationAdapterServiceKey,
  IReportMainEntityFileAdapterServiceKey,
  IReportMainEntityFileUrlAdapterServiceKey,
  IReportMarkerAdapterServiceKey,
  IReportPassportAdapterServiceKey,
  IReportPersonInnAdapterServiceKey,
  IReportPersonSiteAdapterServiceKey,
  IReportPersonSnilsAdapterServiceKey,
  IReportPhotosUrlDTOAdapterServiceKey,
  IReportPledgesRegistryAdapterServiceKey,
  IReportRoadAccidentAdapterServiceKey,
  IReportSocialNetworkHighEducationAdapterServiceKey,
  IReportSocialNetworkIdAdapterServiceKey,
  IReportSocialNetworkJobAdapterServiceKey,
  IReportSocialNetworkMilitaryRecordAdapterServiceKey,
  IReportSocialNetworkSchoolAdapterServiceKey,
  IReportSocialNetworkSecondaryEducationAdapterServiceKey,
  IReportSocialNetworkTrackAdapterServiceKey,
  IReportTagAdapterServiceKey,
  IReportUrlAdapterServiceKey,
  IReportVehicleAdapterServiceKey,
  IReportVehicleInsuranceAdapterServiceKey,
  IReportVehicleOwnershipAdapterServiceKey,
  IReportVehicleRelationAdapterServiceKey,
  IReportVehicleRestrictAdapterServiceKey,
  IReportWantedCarAdapterServiceKey,
  IReportConnectionRelatedEntityDisplayAdapterServiceKey,
  IReportConnectionRelatedEntitiesAdapterServiceKey,
  IReportConnectionRelatedRelationsAdapterServiceKey,
  IReportAddressAdapterServiceKey,
  IReportVehicleParkingAdapterServiceKey,
  IReportMentionAdapterServiceKey,
  IReportEducationAdapterServiceKey,
  IReportPaymentAdapterServiceKey,
  IReportColleagueAdapterServiceKey,
  IReportJobAdapterServiceKey,
  IReportIndividualEntrepreneurAdapterServiceKey,
  IReportFineAdapterServiceKey,
  IReportPossibleFullNameAdapterServiceKey,
  IReportPossibleBirthdaysAdapterServiceKey,
  IReportMovementCovidAdapterServiceKey,
  IReportMovementTaxiAdapterServiceKey,
  IReportMessageBoardRecordAdapterServiceKey,
  IReportSocialNetworkLifePositionAdapterServiceKey,
  IReportSocialNetworkAboutInfoAdapterServiceKey,
  IReportSocialNetworkAdapterServiceKey,
  IReportSocialNetworkOtherPersonAdapterServiceKey,
  IReportVehicleRisksAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { IReportArrayAdapterServiceKey } from '~/services/adapters/common/reportArrayAdapterService.interfaces';
import { ReportArrayStringAdapterService } from '~/services/adapters/common/reportArrayStringAdapterService';
import { ReportMainEntityFileAdapterService } from '~/services/adapters/common/reportMainEntityFileAdapterService';
import { ReportLegalEntitiesParticipationAdapterService } from '~/services/adapters/common/reportLegalEntitiesParticipationAdapterService';
import { ReportVehicleAdapterService } from '~/services/adapters/common/reportVehicleAdapterService';
import { ReportPhotosUrlDTOAdapterService } from '~/services/adapters/common/reportPhotosUrlDTOAdapterService';
import { ReportVehicleRestrictAdapterService } from '~/services/adapters/common/reportVehicleRestrictAdapterService';
import { ReportWantedCarAdapterService } from '~/services/adapters/common/ReportWantedCarAdapterService';
import { ReportPledgesRegistryAdapterService } from '~/services/adapters/common/reportPledgesRegistryAdapterService';
import { ReportMainEntityFileUrlAdapterService } from '~/services/adapters/common/reportMainEntityFileUrlAdapterService';
import { ReportVehicleInsuranceAdapterService } from '~/services/adapters/common/reportVehicleInsuranceAdapterService';
import { ReportVehicleOwnershipAdapterService } from '~/services/adapters/common/reportVehicleOwnershipAdapterService';
import { ReportVehicleRelationAdapterService } from '~/services/adapters/common/reportVehicleRelationAdapterService';
import { ReportRoadAccidentAdapterService } from '~/services/adapters/common/reportRoadAccidentAdapterService';
import { ReportMarkerAdapterService } from '~/services/adapters/common/reportMarkerAdapterService';
import { ReportPersonInnAdapterService } from '~/services/adapters/common/reportPersonInnAdapterService';
import { ReportPersonSnilsAdapterService } from '~/services/adapters/common/reportPersonSnilsAdapterService';
import { ReportPassportAdapterService } from '~/services/adapters/common/reportPassportAdapterService';
import { ReportArrayAdapterService } from '~/services/adapters/common/reportArrayAdapterService';
import { ReportTagAdapterService } from '~/services/adapters/common/reportTagAdapterService';
import { ReportBoundedPersonPhoneAdapterService } from '~/services/adapters/common/reportBoundedPersonPhoneAdapterService';
import { ReportEmailAdapterService } from '~/services/adapters/common/reportEmailAdapterService';
import { ReportPersonSiteAdapterService } from '~/services/adapters/common/reportPersonSiteAdapterService';
import { ReportSocialNetworkIdAdapterService } from '~/services/adapters/common/reportSocialNetworkIdAdapterService';
import { ReportSocialNetworkSchoolAdapterService } from '~/services/adapters/common/reportSocialNetworkSchoolAdapterService';
import { ReportSocialNetworkSecondaryEducationAdapterService } from '~/services/adapters/common/reportSocialNetworkSecondaryEducationAdapterService';
import { ReportSocialNetworkHighEducationAdapterService } from '~/services/adapters/common/reportSocialNetworkHighEducationAdapterService';
import { ReportSocialNetworkMilitaryRecordAdapterService } from '~/services/adapters/common/reportSocialNetworkMilitaryRecordAdapterService';
import { ReportSocialNetworkJobAdapterService } from '~/services/adapters/common/reportSocialNetworkJobAdapterService';
import { ReportSocialNetworkTrackAdapterService } from '~/services/adapters/common/reportSocialNetworkTrackAdapterService';
import { ReportConnectionRelatedAdapterService } from '~/services/adapters/common/reportConnectionRelatedAdapterService';
import { ReportConnectionRelatedEntityDisplayAdapterService } from '~/services/adapters/common/reportConnectionRelatedEntityDisplayAdapterService';
import { ReportConnectionRelatedEntitiesAdapterService } from '~/services/adapters/common/reportConnectionRelatedEntitiesAdapterService';
import { ReportConnectionRelatedRelationsAdapterService } from '~/services/adapters/common/reportConnectionRelatedRelationsAdapterService';
import { ReportAddressAdapterService } from '~/services/adapters/common/reportAddressAdapterService';
import { ReportMentionAdapterService } from '~/services/adapters/common/reportMentionAdapterService';
import { ReportEducationAdapterService } from '~/services/adapters/common/reportEducationAdapterService';
import { ReportPaymentAdapterService } from '~/services/adapters/common/reportPaymentAdapterService';
import { ReportColleagueAdapterService } from '~/services/adapters/common/reportColleagueAdapterService';
import { ReportJobAdapterService } from '~/services/adapters/common/reportJobAdapterService';
import { ReportIndividualEntrepreneurAdapterService } from '~/services/adapters/common/reportIndividualEntrepreneurAdapterService';
import { ReportFineAdapterService } from '~/services/adapters/common/reportFineAdapterService';
import { ReportPossibleFullNameAdapterService } from '~/services/adapters/common/reportPossibleFullNameAdapterService';
import { ReportPossibleBirthdaysAdapterService } from '~/services/adapters/common/reportPossibleBirthdaysAdapterService';
import { ReportMovementCovidAdapterService } from '~/services/adapters/common/reportMovementCovidAdapterService';
import { ReportMovementTaxiAdapterService } from '~/services/adapters/common/reportMovementTaxiAdapterService';
import {
  ReportMessageBoardRecordAdapterService
} from '~/services/adapters/common/reportMessageBoardRecordAdapterService';
import { ReportSocialNetworkAboutInfoAdapterService } from '~/services/adapters/common/reportSocialNetworkAboutInfoAdapterService';
import { ReportSocialNetworkAdapterService } from '~/services/adapters/common/reportSocialNetworkAdapterService';
import { ReportSocialNetworkLifePositionAdapterService } from '~/services/adapters/common/reportSocialNetworkLifePositionAdapterService';
import { ReportSocialNetworkOtherPersonAdapterService } from '~/services/adapters/common/reportSocialNetworkOtherPersonAdapterService';
import { ReportVehicleRisksAdapterService } from '~/services/adapters/common/reportVehicleRisksAdapterService';

export function bindCommonAdapterServices(bind: IContainerBinder) {
  bind(IReportVehicleAdapterServiceKey).to(ReportVehicleAdapterService);
  bind(IReportVettingInfoAdapterServiceKey).to(ReportVettingInfoAdapterService);
  bind(IReportVehicleParkingAdapterServiceKey).to(ReportVehicleParkingAdapterService);
  bind(IReportUrlAdapterServiceKey).to(ReportUrlAdapterService);
  bind(IReportArrayStringAdapterServiceKey).to(ReportArrayStringAdapterService);
  bind(IReportMainEntityFileAdapterServiceKey).to(ReportMainEntityFileAdapterService);
  bind(IReportLegalEntitiesParticipationAdapterServiceKey).to(ReportLegalEntitiesParticipationAdapterService);
  bind(IReportPhotosUrlDTOAdapterServiceKey).to(ReportPhotosUrlDTOAdapterService);
  bind(IReportVehicleRestrictAdapterServiceKey).to(ReportVehicleRestrictAdapterService);
  bind(IReportWantedCarAdapterServiceKey).to(ReportWantedCarAdapterService);
  bind(IReportPledgesRegistryAdapterServiceKey).to(ReportPledgesRegistryAdapterService);
  bind(IReportMainEntityFileUrlAdapterServiceKey).to(ReportMainEntityFileUrlAdapterService);
  bind(IReportVehicleInsuranceAdapterServiceKey).to(ReportVehicleInsuranceAdapterService);
  bind(IReportVehicleOwnershipAdapterServiceKey).to(ReportVehicleOwnershipAdapterService);
  bind(IReportVehicleRelationAdapterServiceKey).to(ReportVehicleRelationAdapterService);
  bind(IReportRoadAccidentAdapterServiceKey).to(ReportRoadAccidentAdapterService);
  bind(IReportMarkerAdapterServiceKey).to(ReportMarkerAdapterService);
  bind(IReportPersonInnAdapterServiceKey).to(ReportPersonInnAdapterService);
  bind(IReportPersonSnilsAdapterServiceKey).to(ReportPersonSnilsAdapterService);
  bind(IReportPassportAdapterServiceKey).to(ReportPassportAdapterService);
  bind(IReportArrayAdapterServiceKey).to(ReportArrayAdapterService);
  bind(IReportTagAdapterServiceKey).to(ReportTagAdapterService);
  bind(IReportBoundedPersonPhoneAdapterServiceKey).to(ReportBoundedPersonPhoneAdapterService);
  bind(IReportEmailAdapterServiceKey).to(ReportEmailAdapterService);
  bind(IReportPersonSiteAdapterServiceKey).to(ReportPersonSiteAdapterService);
  bind(IReportSocialNetworkIdAdapterServiceKey).to(ReportSocialNetworkIdAdapterService);
  bind(IReportSocialNetworkSchoolAdapterServiceKey).to(ReportSocialNetworkSchoolAdapterService);
  bind(IReportSocialNetworkSecondaryEducationAdapterServiceKey).to(ReportSocialNetworkSecondaryEducationAdapterService);
  bind(IReportSocialNetworkHighEducationAdapterServiceKey).to(ReportSocialNetworkHighEducationAdapterService);
  bind(IReportSocialNetworkMilitaryRecordAdapterServiceKey).to(ReportSocialNetworkMilitaryRecordAdapterService);
  bind(IReportSocialNetworkJobAdapterServiceKey).to(ReportSocialNetworkJobAdapterService);
  bind(IReportSocialNetworkTrackAdapterServiceKey).to(ReportSocialNetworkTrackAdapterService);
  bind(IReportConnectionRelatedAdapterServiceKey).to(ReportConnectionRelatedAdapterService);
  bind(IReportConnectionRelatedEntitiesAdapterServiceKey).to(ReportConnectionRelatedEntitiesAdapterService);
  bind(IReportConnectionRelatedEntityDisplayAdapterServiceKey).to(ReportConnectionRelatedEntityDisplayAdapterService);
  bind(IReportConnectionRelatedRelationsAdapterServiceKey).to(ReportConnectionRelatedRelationsAdapterService);
  bind(IReportAddressAdapterServiceKey).to(ReportAddressAdapterService);
  bind(IReportMentionAdapterServiceKey).to(ReportMentionAdapterService);
  bind(IReportEducationAdapterServiceKey).to(ReportEducationAdapterService);
  bind(IReportPaymentAdapterServiceKey).to(ReportPaymentAdapterService);
  bind(IReportColleagueAdapterServiceKey).to(ReportColleagueAdapterService);
  bind(IReportJobAdapterServiceKey).to(ReportJobAdapterService);
  bind(IReportIndividualEntrepreneurAdapterServiceKey).to(ReportIndividualEntrepreneurAdapterService);
  bind(IReportFineAdapterServiceKey).to(ReportFineAdapterService);
  bind(IReportPossibleFullNameAdapterServiceKey).to(ReportPossibleFullNameAdapterService);
  bind(IReportPossibleBirthdaysAdapterServiceKey).to(ReportPossibleBirthdaysAdapterService);
  bind(IReportMovementCovidAdapterServiceKey).to(ReportMovementCovidAdapterService);
  bind(IReportMovementTaxiAdapterServiceKey).to(ReportMovementTaxiAdapterService);
  bind(IReportMessageBoardRecordAdapterServiceKey).to(ReportMessageBoardRecordAdapterService);
  bind(IReportSocialNetworkOtherPersonAdapterServiceKey).to(ReportSocialNetworkOtherPersonAdapterService);
  bind(IReportSocialNetworkAboutInfoAdapterServiceKey).to(ReportSocialNetworkAboutInfoAdapterService);
  bind(IReportSocialNetworkLifePositionAdapterServiceKey).to(ReportSocialNetworkLifePositionAdapterService);
  bind(IReportSocialNetworkAdapterServiceKey).to(ReportSocialNetworkAdapterService);
  bind(IReportVehicleRisksAdapterServiceKey).to(ReportVehicleRisksAdapterService);
}
