import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';

import { ReportJobActivityConfigService } from '~/modules/reportsCommon/reportJobs/services/reportJobActivityConfigService';
import {
  IReportJobActivityConfigServiceKey,
  IReportJobCompanyNameConfigServiceKey,
  IReportJobCompanyPhoneConfigServiceKey
} from '~/modules/reportsCommon/reportJobs/services/reportJobConfigService.interfaces';
import { ReportJobCompanyPhoneConfigService } from '~/modules/reportsCommon/reportJobs/services/reportJobCompanyPhoneConfigService';
import { ReportJobCompanyNameConfigService } from '~/modules/reportsCommon/reportJobs/services/reportJobCompanyNameConfigService';
import { ReportJobMergeService } from '~/modules/reportsCommon/reportJobs/services/reportJobMergeService';
import { IReportJobMergeServiceKey } from '~/modules/reportsCommon/reportJobs/services/reportJobMergeService.interfaces';

export function bindReportJobServices(bind: IContainerBinder) {
  bind(IReportJobActivityConfigServiceKey).to(ReportJobActivityConfigService);
  bind(IReportJobCompanyPhoneConfigServiceKey).to(ReportJobCompanyPhoneConfigService);
  bind(IReportJobCompanyNameConfigServiceKey).to(ReportJobCompanyNameConfigService);

  bind(IReportJobMergeServiceKey).to(ReportJobMergeService);
}
