import { injectable, inject } from 'inversify';
import {
  ILegalEntitiesGeneralInformationLeaderDataProfileService
} from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesGeneralInformationDataPropfileService.interfaces';
import {
  CompanyDataProfiles
} from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/types/companyDataProfiles';
import { LegalEntitiesCompanyHeadFlDTO } from '~/types/dto/legalEntities/companyHeadFlDTO';
import {
  ILegalEntitiesGeneralInformationLeaderConfigService,
  ILegalEntitiesGeneralInformationLeaderConfigServiceKey
} from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesGeneralInformationConfigServices.interfaces';
import {
  ILegalEntitiesGeneralInformationLeaderInfoConfigService,
  ILegalEntitiesGeneralInformationLeaderInfoConfigServiceKey
} from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesGeneralInformationLeaderInfoConfigService.interfaces';

@injectable()
export class LegalEntitiesGeneralInformationLeaderDataProfileService implements ILegalEntitiesGeneralInformationLeaderDataProfileService {
  constructor(
    @inject(ILegalEntitiesGeneralInformationLeaderConfigServiceKey as symbol) private leaderConfigService: ILegalEntitiesGeneralInformationLeaderConfigService,
    @inject(ILegalEntitiesGeneralInformationLeaderInfoConfigServiceKey as symbol) private leaderInfoConfigService: ILegalEntitiesGeneralInformationLeaderInfoConfigService,
  ) {}

  getDataProfile(initData: Array<LegalEntitiesCompanyHeadFlDTO>): Array<CompanyDataProfiles> {
    return initData.map((leader) => ({
      name: this.leaderConfigService.getConfig(leader),
      profileInfo: this.leaderInfoConfigService.getConfig(leader, initData),
    }));
  }
}
