import { injectable } from 'inversify';
import { get } from 'lodash-es';
import { ConnectionsUtils } from '~/modules/reportsCommon/reportConnection/utils/connectionsUtils';

import { ReportConnectionRelatedDTO } from '~/types/dto/common/reportConnectionRelatedDTO';
import {
  IReportConnectionParseService,
  ReportConnectionParsedChains,
  ReportConnectionRelatedInfo,
} from '~/modules/reportsCommon/reportConnection/services/reportConnectionsService.interfaces';

@injectable()
export class ReportConnectionsParseService implements IReportConnectionParseService {
  connections: ReportConnectionRelatedDTO[];
  constructor() {
    this.connections = [];
  }
  getParsedConnections(connections: ReportConnectionRelatedDTO[]): ReportConnectionParsedChains {
    this.connections = ConnectionsUtils.getUniqConnections(connections);

    return this.parseConnections();
  }

  protected parseConnections(): ReportConnectionParsedChains {
    return this.connections.map((connectionChain: ReportConnectionRelatedDTO) => {
      const { Entities, Relations } = connectionChain;
      let relatedEntities: ReportConnectionRelatedInfo[] = [];

      Entities.forEach((currentEntity, index) => {
        const nextEntity = get(Entities, index + 1);

        if (nextEntity) {
          const currentRelation = get(Relations, index);

          const relatedEntity = {
            RelatedEntities: Entities.slice(index, index + 2),
            RelatedRelation: currentRelation,
          };

          relatedEntities = [...relatedEntities, relatedEntity];
        }
      });

      return relatedEntities;
    });
  }
}
