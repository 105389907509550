import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import {
  IIndividualMovementsCovidConfigServiceKey,
  IIndividualMovementsAviasConfigServiceKey, IIndividualMovementsBusConfigServiceKey
} from '~/modules/reportsCommon/reportMovement/individual/services/individualMovementsConfigService.interfaces';
import {
  IndividualMovementsAviasConfigService
} from '~/modules/reportsCommon/reportMovement/individual/services/individualMovementsAviasConfigService';
import {
  IndividualMovementsBusConfigService
} from '~/modules/reportsCommon/reportMovement/individual/services/individualMovementsBusConfigService';
import {
  IndividualMovementsCovidConfigServices
} from '~/modules/reportsCommon/reportMovement/individual/services/individualMovementsCovidConfigServices';

export function bindIndividualMovementsConfigServices(bind: IContainerBinder) {
  bind(IIndividualMovementsAviasConfigServiceKey).to(IndividualMovementsAviasConfigService);
  bind(IIndividualMovementsBusConfigServiceKey).to(IndividualMovementsBusConfigService);
  bind(IIndividualMovementsCovidConfigServiceKey).to(IndividualMovementsCovidConfigServices);
}
