import axios, { AxiosInstance } from 'axios';
import { InjectionKey } from 'vue';
import { appSettings } from '@/settings';
import useEnvironment from '@/composables/useEnvironment';
import { Environment } from '@/types/Environment';

export const APIEndpointKey: InjectionKey<AxiosInstance> = Symbol('AxiosInstance');

export function useAPIEndpoint() {
  const environment = useEnvironment();

  return axios.create({
    baseURL: environment === Environment.server
      ? appSettings.dataEndpointInternalURL
      : appSettings.dataEndpointURL,

    headers: {
      XApiKey: appSettings.token,
    },
  });
}
