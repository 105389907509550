// to-do Раскидать по уже существующим утилитам
export module AviasUtils {
  export function formatFlightStartTime(time: string | undefined) {
    if (!time) {
      return '';
    }
    if (time.includes(':')) {
      return time;
    }
    return time.length === 4 ? `${time.slice(0, 2)}:${time.slice(2, 4)}` : '';
  }

  export function formatAviasTripCompanions(
    companions: Array<string> | undefined,
  ) {
    if (companions === undefined) {
      return { length: 0, value: '' };
    }
    const result = companions.map((companion) => companion.replace('(', ' ('));
    return { length: result.length, value: result.join(', ') };
  }
}
