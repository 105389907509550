import { injectable } from 'inversify';
import { ReportTagDTO } from '~/types/dto/common/reportTagDTO';
import { IReportTagAdapterService } from './reportTagAdapterService.interfaces';

@injectable()
export class ReportTagAdapterService implements IReportTagAdapterService {
  getData(initData: ReportTagDTO[]): string[] {
    return initData.flatMap((item) => item.Tag.split(';')
      .map(tag => ({ Tag: tag.trim() }))).map((tag) => `# ${tag.Tag}`);
  }
}
