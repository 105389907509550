import { injectable } from 'inversify';
import { IReportJobCompanyNameConfigService } from '~/modules/reportsCommon/reportJobs/services/reportJobConfigService.interfaces';
import { Config } from '~/types/field-list/config';

@injectable()
export class ReportJobCompanyNameConfigService implements IReportJobCompanyNameConfigService {
  getConfig(item: string): Config {
    return {
      fieldsWidth: '100%',
      fieldsDirection: 'column',
      fields: [
        {
          key: 'CompanyName',
          label: 'Наименование',
          value: item.toUpperCase(),
        }
      ]
    };
  }
}
