import { injectable, inject } from 'inversify';
import { ILegalEntitiesGeneralInformationManagementDataProfileService } from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesGeneralInformationDataPropfileService.interfaces';
import { LegalEntitiesCompanyManagementCompaniesDTO } from '~/types/dto/legalEntities/companyManagementCompaniesDTO';
import { CompanyDataProfiles } from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/types/companyDataProfiles';
import {
  ILegalEntitiesGeneralInformationManagementCompanyConfigService,
  ILegalEntitiesGeneralInformationManagementCompanyConfigServiceKey,
  ILegalEntitiesGeneralInformationManagementCompanyInfoConfigService,
  ILegalEntitiesGeneralInformationManagementCompanyInfoConfigServiceKey
} from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesGeneralInformationConfigServices.interfaces';

@injectable()
export class LegalEntitiesGeneralInformationManagementDataProfileService implements ILegalEntitiesGeneralInformationManagementDataProfileService {
  constructor(
    @inject(ILegalEntitiesGeneralInformationManagementCompanyConfigServiceKey as symbol) private managementCompanyConfigService: ILegalEntitiesGeneralInformationManagementCompanyConfigService,
    @inject(ILegalEntitiesGeneralInformationManagementCompanyInfoConfigServiceKey as symbol) private managementCompanyInfoConfigService: ILegalEntitiesGeneralInformationManagementCompanyInfoConfigService
  ) {}

  getDataProfile(initData: Array<LegalEntitiesCompanyManagementCompaniesDTO>): Array<CompanyDataProfiles> {
    return initData.map((manager) => ({
      name: this.managementCompanyConfigService.getConfig(manager),
      profileInfo: this.managementCompanyInfoConfigService.getConfig(manager)
    }));
  }
}
