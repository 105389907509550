import { InjectionKey } from 'vue';
import { IConfigService } from '~/services/configService.interface';
import { ReportJobDTO } from '~/types/dto/common/reportJobDTO';

export type IReportJobActivityConfigService = IConfigService<ReportJobDTO>;
export type IReportJobCompanyPhoneConfigService = IConfigService<ReportJobDTO>;
export type IReportJobCompanyNameConfigService = IConfigService<string>;

export const IReportJobActivityConfigServiceKey: InjectionKey<IReportJobActivityConfigService> = Symbol('IReportJobActivityConfigService');
export const IReportJobCompanyPhoneConfigServiceKey: InjectionKey<IReportJobCompanyPhoneConfigService> = Symbol('IReportJobCompanyPhoneConfigService');
export const IReportJobCompanyNameConfigServiceKey: InjectionKey<IReportJobCompanyNameConfigService> = Symbol('IReportJobCompanyNameConfigService');
