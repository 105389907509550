import { injectable } from 'inversify';
import { orderBy } from 'lodash-es';
import { IReportVehicleRelationAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';

import { DateUtils } from '~/utils/date';
import { StringUtils } from '~/utils/stringUtils';

import { ReportVehicleRelation } from '~/types/common/reportVehicleRelation';
import { ReportVehicleRelationDTO } from '~/types/dto/common/reportVehicleRelationDTO';
import { REMOVE_SPACE_REGEX } from '~/regex/removeSpace';
import { PtsUtils } from '~/utils/ptsUtils';

@injectable()
export class ReportVehicleRelationAdapterService implements IReportVehicleRelationAdapterService {
  getData(initData: ReportVehicleRelation[]): ReportVehicleRelationDTO[] {
    return orderBy(initData ?? [], ['RegDate'], ['desc']).map((relation) => {
      const ptsWithoutSpaces = (relation.Pts ?? '').replace(REMOVE_SPACE_REGEX, '');
      const isValidPts = PtsUtils.getIsValidPts(ptsWithoutSpaces);
      const pts = isValidPts ? PtsUtils.formattedPts(ptsWithoutSpaces) : '';

      const name = StringUtils.quoteReplacement(relation.Name ? relation.Name.trim() : '');
      
      return {
        SourceType: relation.SourceType,
        RegDate: DateUtils.formatDate(relation.RegDate),
        DeregistrationDate: DateUtils.formatDate(relation.DeregistrationDate),
        Name: name,
        Birthday: DateUtils.formatDate(relation.Birthday),
        PhoneNumber: relation.PhoneNumber ?? '',
        Sts: relation.Sts ?? '',
        Pts: pts,
        Inn: relation.Inn ?? '',
        PtsIssuedDate: relation.PtsIssuedDate ?? '',
        Passport: relation.Passport,
        Address: relation.Address ?? '',
        PtsDepartmentName: relation.PtsDepartmentName,
      }; 
    })
      .filter(
        (item) => item.RegDate
                    || item.DeregistrationDate
                    || item.Name
                    || item.Birthday
                    || item.PhoneNumber
                    || item.Sts
                    || item.Pts,
      );
  }
}
