import { injectable } from 'inversify';
import { ILegalEntitiesHasInformationCreditOrganizationsService } from '~/modules/legalEntities/registrationData/legalEntitiesInformationCreditOrganizations/services/legalEntitiesHasInformationCreditOrganizationsService.interface';
import { LegalEntitiesCommonInfoDTO } from '~/types/dto/legalEntities/commonInfo';

@injectable()
export class LegalEntitiesHasInformationCreditOrganizationsService implements ILegalEntitiesHasInformationCreditOrganizationsService {
  checkHasInformationCreditOrganizations(initData: LegalEntitiesCommonInfoDTO): boolean {
    return initData.Bic.length !== 0
            || initData.Swift.length !== 0
            || initData.BankRegistrationNumber.length !== 0
            || initData.BankCorrespondentAccount.length !== 0
            || initData.BankOpfType.length !== 0;
  }
}
