import { get, isArray } from 'lodash-es';
import { NUMBER_DIGIT_GROUPS_EXTRACTION_REGEX } from '~/regex/numberDigitGroupsExtraction';
import { DECIMAL_SEARCH_REGEX } from '~/regex/numberDecimalSearch';
import { StringUtils } from './stringUtils';

export module NumberUtils {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: ' тыс.' },
    { value: 1e6, symbol: ' млн.' },
    { value: 1e9, symbol: ' млрд.' },
    { value: 1e12, symbol: ' трл.' },
    { value: 1e15, symbol: ' квадрлн.' },
    { value: 1e18, symbol: ' квинтлн.' }
  ];
  export const upToInt = (num: number) => {
    const ceil = Math.ceil(num);
    const itemLength = `${ceil}`.length;

    return +(Math.ceil(+`0.${ceil}`) + '0'.repeat(itemLength));
  };
  export const findItem = (num: number) => [...lookup].reverse().find((item) => num >= item.value);
  export const getPostfix = (num: number) => findItem(Math.abs(num))?.symbol || '';
  export const postfixFormat = (num: number, digits: number = 1): string => {
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = findItem(Math.abs(num));
    return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
  };
  export const getScaleInterval = (num: number) => {
    const l = Number(1 + '0'.repeat(String(Math.ceil(num)).length));
    const k = Math.abs(num / l);
    const g = (num: number) => num * l;

    switch (true) {
      case k <= 0.2: return g(0.05);
      case k <= 0.5: return g(0.1);
      case k <= 0.6: return g(0.2);
      case k <= 0.9: return g(0.4);
      case k <= 1: return g(0.5);
      default: return g(1);
    }
  };
  export const formatByMax = (num: number, maxNum = num, digits = 1, roundBorderline = 0): number => {
    const item = findItem(maxNum);
    let presentValue = num;
    if (item) {
      presentValue = num / item.value;
    }
    if (Math.abs(presentValue) < roundBorderline) {
      return presentValue;
    }
    return +presentValue.toFixed(item ? digits : 3);
  };

  export const formatForTable = (num: number) : number => {
    const item = num.toFixed(1);
    return +item;
  };
  export const getMaxFromObject = (obj?: Record<string, any>) => (obj
    ? Math.max(
      ...Object.values(obj)
        .filter((data) => data !== null && !Number.isNaN(Number(data)))
        .map((num) => Math.abs(Number(num)))
    )
    : 0);

  // Разделение целой части числа на группы рядов.
  export function formatNumberDigits(
    unformattedNumber?: number,
    fractionDigits: number = 0,
    defaultValue: string = '0',
  ): string {
    if (unformattedNumber == undefined) return defaultValue;

    // Если пришло целое число, тогда отдаем как есть.
    // Если пришло дробное и число округления, то форматируем до указанного знака после запятой.
    const formattedValue = !Number.isInteger(unformattedNumber)
      ? String(unformattedNumber.toFixed(fractionDigits)) : String(unformattedNumber);
    // Получаем целую и дробную части
    const [_, integerValue, decimalValue]: string[] = formattedValue.match(DECIMAL_SEARCH_REGEX) ?? [];
    if (integerValue == undefined || decimalValue == undefined) return defaultValue;
    // Целую часть делим на группы рядов при помощи регулярки, после склеиваем число обратно
    const formattedIntegerValue = integerValue.replace(NUMBER_DIGIT_GROUPS_EXTRACTION_REGEX, ' ');

    return `${formattedIntegerValue}${decimalValue}`;
  }

  export function transformObject(
    obj: Record<string, string | number | string[] | undefined | null> | undefined,
    headers: Record<string | 'value', string | number>[],
    config?: { isPercent?: boolean, },
    digits?: number,
    checkDecimalValue?: boolean
  ) {
    if (!obj) return {};
    const headersToRound = checkDecimalValue 
      ? ['TwoYearsAgo', 'LastYear', 'CurrentYear'] 
      : ['TwoYearsAgoToLastYear', 'LastYearToCurrentYear', 'TwoYearsAgo', 'LastYear', 'CurrentYear'];

    const max = NumberUtils.getMaxFromObject(obj);

    return [...headers.map(({ value }) => String(value)),
      ...Object.keys(obj)].reduce<Record<keyof typeof obj, string>>((acc, key) => {
        const value = obj[key] ?? '-';
        function currentEl(num: number): number | undefined {
          return headersToRound.includes(key) ? NumberUtils.formatByMax(num, max, digits) : num;
        }
        let newValue: null | string | number = null;

        if (isArray(value) && value.length > 0 && get(value, 0) !== '') {
          acc[key] = value.join('\n');
          return acc;
        }

        if (Number.isNaN(Number(value))) {
          newValue = String(value);
        } else if (typeof value === 'number' && Math.abs(value!) < 0.01) {
          newValue = '0.01<';
        } else {
          const valueTo = Number(config?.isPercent ? (Number(value) * 100) : value);
          newValue = Number.isInteger(Number(value))
            ? formatNumberDigits((currentEl(valueTo)), digits ?? 2, '-')
            : Number(currentEl(valueTo)).toFixed(digits ?? 2);
        }
        
        const isLessThenOne = newValue !== '0.01<';
        const resultValue = checkDecimalValue && isLessThenOne ? Number(newValue) : newValue; 

        acc[key] = (resultValue && String(resultValue)) || '-';
        
        return acc;
      }, {});
  }

  export function getNameDegreesOfThousand(obj?: Record<string, any>) {
    return String(NumberUtils.getPostfix(NumberUtils.getMaxFromObject(obj))).trim().replace('.', ',');
  }

  export function formattedToNumber(value: string): number {
    if (StringUtils.isStringNotEmpty(value)) {
      const clearedValue = Number(value.replaceAll(' ', ''));
  
      return clearedValue ?? 0;
    }
    return 0;
  }

  export function toFixedAuto(value: number): string {
    const stringValue = String(value).toLowerCase();

    if (stringValue.includes('e-')) {
      const [_, fraction] = stringValue.split('e-');

      return value.toFixed(Number(fraction));
    }

    return stringValue;
  }
}
