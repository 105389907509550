import { injectable, inject } from 'inversify';
import {
  ILegalEntitiesReportAnalysisOfAbsoluteLiquidityAdapterService,
  ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorAdapterService,
  ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorAdapterServiceKey
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { ReportAnalysisOfAbsoluteLiquidity } from '~/types/legal-entities/reportAnalysisOfAbsoluteLiquidity';
import {
  LegalEntitiesReportAnalysisOfAbsoluteLiquidityDTO
} from '~/types/dto/legalEntities/reportAnalysisOfAbsoluteLiquidity';

@injectable()
export class LegalEntitiesReportAnalysisOfAbsoluteLiquidityAdapterService implements ILegalEntitiesReportAnalysisOfAbsoluteLiquidityAdapterService {
  constructor(
    @inject(ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorAdapterServiceKey as symbol) private legalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorAdapterService: ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorAdapterService,
  ) {}
  getData(initData: ReportAnalysisOfAbsoluteLiquidity): LegalEntitiesReportAnalysisOfAbsoluteLiquidityDTO {
    return {
      MostLiquidAssetsToMostUrgentLiabilities: this.legalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorAdapterService.getData(initData.MostLiquidAssetsToMostUrgentLiabilities),
      MarketableAssetsToOneYearLoanObligations: this.legalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorAdapterService.getData(initData.MarketableAssetsToOneYearLoanObligations),
      SlowSellingAssetsToLongTermBorrowings: this.legalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorAdapterService.getData(initData.SlowSellingAssetsToLongTermBorrowings),
      HardSellingAssetsToCapitalAndReserves: this.legalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorAdapterService.getData(initData.HardSellingAssetsToCapitalAndReserves),
      DegreeOfRepaymentObligationsWithAssets: initData.DegreeOfRepaymentObligationsWithAssets ?? 0,
      IsSolventInShortAndMediumTerm: initData.IsSolventInShortAndMediumTerm,
      IsSolventInShortAndMediumTermConclusion: initData.IsSolventInLongTermConclusion,
      IsSolventInLongTerm: initData.IsSolventInLongTerm,
      IsSolventInLongTermConclusion: initData.IsSolventInLongTermConclusion
    };
  }
}
