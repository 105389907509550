import { ReportPassportDTO } from "~/types/dto/common/reportPassportDTO";
import { Config } from "~/types/field-list/config";
import { IReportPassportConfigService } from "./reportPassportConfigService.interfaces";
import { DateUtils } from "~/utils/date";
import { injectable } from "inversify";

@injectable()
export class ReportPassportConfigService implements IReportPassportConfigService {
  getConfig(passport: ReportPassportDTO): Config {
    const passportStatusMap = new Map();
    passportStatusMap.set(true, 'В списке недействительных не значится');
    passportStatusMap.set(false, 'Значится в списке недействительных');

    const seriesNumber = `${passport.Series} ${passport.Number}`

    return {
      fieldsDirection: 'divider-row',
      fieldsValueWidth: '350px',
      fieldsWidth: '100%',
      isBeginningDivider: true,
      fields: [
        {
          key: 'seriesAndNumber',
          label: 'Серия и номер',
          value: seriesNumber,
        },
        {
          key: 'issuedBy',
          label: {
            text: 'Подразделение, выдавшее документ',
          },
          value: passport.IssuedBy
        },
        {
          key: 'issuedDate',
          label: 'Дата выдачи',
          value: DateUtils.formatDate(passport.IssuedDate)
        },
        {
          key: 'departmentCode',
          label: 'Код подразделения',
          value: passport.DepartmentCode,
        },
        {
          key: 'isActual',
          label: 'Действительность',
          value: passportStatusMap.get(passport.IsActual)
        }
      ]
    }
  }
}