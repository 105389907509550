import { injectable, inject } from 'inversify';
import { DataBlobReportOriginalInput } from '~/types/blobs/data-blob-report-original-input';
import { GenericDataBlob } from '~/types/blobs/generic-data-blob';
import { GenericDataBlobData } from '~/types/blobs/generic-data-blob-data';
import { ListDataBlobWrapperReportData } from '~/types/blobs/list-data-blob-wrapper';
import { IDataBlobReportOriginalInputNormalizationServiceKey, IDataBlobInputDataListNormalizationServiceKey } from './dataBlobNormalizationService.interfaces';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IStringArrayNormalizationServiceKey } from '../common/reportNormalizationService.interfaces';
import { IDataBlobDataWrapperNormalizationService } from './dataBlobDataWrapperNormalizationService.interfaces';
import { UIReportType } from '~/types/reportType';
import { LegalEntitiesDataBlobData } from '~/types/blobs/legal-entities-data-blob-data';
import { ILegalEntitiesDataBlobDataNormalizationServiceKey } from '../legalEntities/legalEntitiesNormalizationService.interfaces';
import { IndividualDataBlobData } from '~/types/blobs/individual-data-blob-data';
import { VehiclesDataBlobData } from '~/types/blobs/vehicles-data-blob-data';
import { IIndividualDataBlobDataNormalizationServiceKey } from '../individual/individualNormalizationService.interfaces';
import { IVehiclesDataBlobDataNormalizationServiceKey } from '../vehicles/vehiclesNormalizationService.interfaces';
import { ILegalEntitiesDataBlobDataPlaceholderServiceKey } from '~/services/placeholder/legalEntities/legalEntitiesPlaceholderDataService.interfaces';
import { IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { IIndividualDataBlobDataPlaceholderServiceKey } from '~/services/placeholder/individual/individualPlaceholderDataService.interfaces';
import { IVehiclesDataBlobDataPlaceholderServiceKey } from '~/services/placeholder/vehicles/vehiclesPlaceholderService.interfaces';
import { ErrorMessages } from '~/resources/errorMessages';
import { IPhoneNumbersDataBlobDataNormalizationServiceKey } from '../phoneNumber/phoneNumberNormalizationService.interface';
import { PhoneNumbersDataBlobData } from '~/types/blobs/phone-numbers-data-blob-data';
import { IPhoneNumbersDataBlobDataPlaceholderServiceKey } from '~/services/placeholder/phoneNumber/phoneNumberPlaceholderService.interface';

@injectable()
export class DataBlobDataWrapperNormalizationService implements IDataBlobDataWrapperNormalizationService<GenericDataBlob> {
  constructor(
    @inject(ILegalEntitiesDataBlobDataNormalizationServiceKey as symbol) private legalEntitiesDataBlobDataNormalizationService: INormalizationService<LegalEntitiesDataBlobData>,
    @inject(IIndividualDataBlobDataNormalizationServiceKey as symbol) private individualDataBlobDataNormalizationService: INormalizationService<IndividualDataBlobData>,
    @inject(IVehiclesDataBlobDataNormalizationServiceKey as symbol) private vehiclesDataBlobDataNormalizationService: INormalizationService<VehiclesDataBlobData>,
    @inject(IPhoneNumbersDataBlobDataNormalizationServiceKey as symbol) private phoneNumbersDataBlobDataNormalizationService: INormalizationService<PhoneNumbersDataBlobData>,

    @inject(IStringArrayNormalizationServiceKey as symbol) private stringArrayNormalizationService: INormalizationService<string>,
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
    @inject(IDataBlobReportOriginalInputNormalizationServiceKey as symbol) private dataBlobReportOriginalInputNormalizationService: INormalizationService<DataBlobReportOriginalInput>,
    @inject(IDataBlobInputDataListNormalizationServiceKey as symbol) private dataBlobInputDataListNormalizationService: IDataBlobDataWrapperNormalizationService<ListDataBlobWrapperReportData<GenericDataBlobData>>,

    @inject(ILegalEntitiesDataBlobDataPlaceholderServiceKey as symbol) private legalEntitiesDataBlobDataPlaceholderService: IPlaceholderDataService<LegalEntitiesDataBlobData>,
    @inject(IIndividualDataBlobDataPlaceholderServiceKey as symbol) private individualDataBloDataPlaceholderService: IPlaceholderDataService<IndividualDataBlobData>,
    @inject(IVehiclesDataBlobDataPlaceholderServiceKey as symbol) private vehiclesDataBlobDataPlaceholderService: IPlaceholderDataService<VehiclesDataBlobData>,
    @inject(IPhoneNumbersDataBlobDataPlaceholderServiceKey as symbol) private phoneNumbersDataBlobDataPlaceholderService: IPlaceholderDataService<PhoneNumbersDataBlobData>

  ) {}

  normalize(type: UIReportType, data: any): GenericDataBlob | undefined {
    const {
      normalizeNumber,
      normalizeArray,
      normalizeString,
      normalizeObject,
      normalizeOptional,
      normalizeOptionalString
    } = this.primitiveTypesNormalizationService;

    const normalizedData = (type: UIReportType, data: any) => this.normalizedData(type, data);

    const normalizedBlobInputDataList = (
      data: ListDataBlobWrapperReportData<GenericDataBlobData>,
    ) => this.dataBlobInputDataListNormalizationService.normalize(type, data, normalizedData);

    return normalizeObject(data, (data) => ({
      ReportGraphId: normalizeOptionalString(data.ReportGraphId, `${type}.ReportGraphId`),
      ReportInputData: this.normalizedData(type, data.ReportInputData) ?? this.getPlaceholderData(type),
      ReportData: normalizeOptional(data.ReportData, normalizedBlobInputDataList, `${type}.ReportData`),
      ReportType: normalizeNumber(data.ReportType, `${type}.ReportType`),
      MainEntitiesIds: normalizeArray(data.MainEntitiesIds, (item, field) => this.stringArrayNormalizationService.normalize(item, field), `${type}.MainEntitiesIds`),
      ReportGenerationDateTime: normalizeString(data.ReportGenerationDateTime, `${type}.ReportGenerationDateTime`),
      ReportTypeRus: normalizeString(data.ReportTypeRus, `${type}.ReportTypeRus`),
      OriginalInput: normalizeOptional(data.OriginalInput, (data, field) => this.dataBlobReportOriginalInputNormalizationService.normalize(data, field), `${type}.OriginalInput`)
    }), type);
  }

  private normalizedData(type: UIReportType, data: any): GenericDataBlobData | undefined {
    switch (type) {
      case (UIReportType.ul):
        return this.legalEntitiesDataBlobDataNormalizationService.normalize(data, UIReportType.ul);
      case (UIReportType.fl):
        return this.individualDataBlobDataNormalizationService.normalize(data, UIReportType.fl);
      case (UIReportType.vehicle):
        return this.vehiclesDataBlobDataNormalizationService.normalize(data, UIReportType.vehicle);
      case (UIReportType.phoneNumber):
        return this.phoneNumbersDataBlobDataNormalizationService.normalize(data, UIReportType.phoneNumber);
      default:
        return undefined;
    }
  }

  private getPlaceholderData(type: UIReportType): GenericDataBlobData {
    switch (type) {
      case UIReportType.ul:
        return this.legalEntitiesDataBlobDataPlaceholderService.getData();
      case UIReportType.fl:
        return this.individualDataBloDataPlaceholderService.getData();
      case UIReportType.vehicle:
        return this.vehiclesDataBlobDataPlaceholderService.getData();
      case UIReportType.phoneNumber:
        return this.phoneNumbersDataBlobDataPlaceholderService.getData();

      default: 
        throw new Error(ErrorMessages.NORMALIZE_TYPE_NOTE_FOUND);
    }
  }
}
