import { InjectionKey } from "nuxt/dist/app/compat/capi";
import { ReportGenerationTask } from "~/types/report/report";
import { APIReportType } from "~/types/reportType";

export interface IReportsService {
  addReportsToList(report: Array<File>): Promise<void>;
  generateReport(reportType: APIReportType, report: File): Promise<void>;
  generateReportManually(reportTakId: string): Promise<ReportGenerationTask>;
}

export const IReportsServiceKey: InjectionKey<IReportsService> = Symbol('IReportsService');