import { injectable, inject } from 'inversify';
import { sortBy } from 'lodash-es';
import {
  IReportBoundedPersonPhoneAdapterService,
  IReportEmailAdapterService,
  IReportEmailAdapterServiceKey,
  IReportTagAdapterService,
  IReportTagAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { StringUtils } from '~/utils/stringUtils';
import { ReportBoundedPersonPhone } from '~/types/common/reportBoundedPersonPhone';
import { ReportBoundedPersonPhoneDTO } from '~/types/dto/common/boundedPersonPhoneDTO';
import { PhoneUtils } from '~/utils/phone';
import { DateUtils } from '~/utils/date';

@injectable()
export class ReportBoundedPersonPhoneAdapterService implements IReportBoundedPersonPhoneAdapterService {
  constructor(
    @inject(IReportTagAdapterServiceKey as symbol) private reportTagAdapterService: IReportTagAdapterService,
    @inject(IReportEmailAdapterServiceKey as symbol) private reportEmailAdapterService: IReportEmailAdapterService
  ) {}
  getData(initData: ReportBoundedPersonPhone[]): ReportBoundedPersonPhoneDTO[] {
    const sortedInitData = sortBy(initData, [
      (item) => this.compareBySourceStr(item)
    ]);

    return sortedInitData.map((item): ReportBoundedPersonPhoneDTO => ({
      Value: PhoneUtils.getIsValidPhoneNumber(item.Value) ? PhoneUtils.formatPhoneNumberWithDash(item.Value) : '',
      Source: item.Source,
      SourceStr: StringUtils.quoteReplacement(item.SourceStr),
      Tag: StringUtils.quoteReplacement(item.Tag ?? ''),
      Tags: this.reportTagAdapterService.getData(item.Tags),
      FullName: StringUtils.quoteReplacement(item.FullName),
      RelatedAddress: item.RelatedAddress ?? '',
      RelatedBirth: DateUtils.isValidDate(item.RelatedBirth ?? '') 
        ? DateUtils.formatDate(item.RelatedBirth) 
        : '',
      Emails: this.reportEmailAdapterService.getData(item.Emails),
      OriginalRequestData: PhoneUtils.getIsValidPhoneNumber(item.OriginalRequestData ?? '') 
        ? PhoneUtils.formatPhoneNumberWithDash(item.OriginalRequestData ?? '') : ''
    })).filter((item) => item.Value);
  }

  private compareBySourceStr(phone: ReportBoundedPersonPhone): number {
    if (StringUtils.quoteReplacement(phone.SourceStr.toLowerCase()) === 'телефонная книга') return 2;
    if (StringUtils.quoteReplacement(phone.SourceStr.toLowerCase()) === 'парковка') return 1;
    return 0;
  }
}
