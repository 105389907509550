/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { StringUtils } from '~/utils/stringUtils';
import { ReportLegalEntitiesParticipation } from '~/types/common/reportLegalEntitiesParticipation';
import { ReportLegalEntitiesParticipationDTO } from '~/types/dto/common/reportLegalEntitiesParticipationDTO';
import {
  IReportLegalEntitiesParticipationAdapterService
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { LegalEntitiesParticipationUtils } from '~/modules/reportsCommon/reportLegalEntitiesParticipation/utils/legalEntitiesParticipationUtils';

@injectable()
export class ReportLegalEntitiesParticipationAdapterService implements IReportLegalEntitiesParticipationAdapterService {
  getData(initData: ReportLegalEntitiesParticipation[]): ReportLegalEntitiesParticipationDTO[] {
    return (initData ?? []).map((item) => {
      const isHistoric = item.IsHistoric ?? false;

      const participationType = StringUtils.quoteReplacement(item.ParticipationType || '');
      const hasParticipationType = StringUtils.isStringNotEmpty(participationType);
      
      const participationTypeLabel = LegalEntitiesParticipationUtils.convertStringToParticipation(
        isHistoric && hasParticipationType ? `${participationType} (бывший)` : participationType
      );
      
      return {
        Shortname: StringUtils.quoteReplacement(item.Shortname ?? ''),
        Status: StringUtils.quoteReplacement(item.Status ?? ''),
        Address: StringUtils.quoteReplacement(item.Address ?? ''),
        AuthorizedCapitalSum: item.AuthorizedCapitalSum ?? 0,
        CompanyName: StringUtils.quoteReplacement(item.CompanyName ?? ''),
        Inn: StringUtils.quoteReplacement(item.Inn ?? ''),
        IsHistoric: isHistoric,
        LiquidationDate: StringUtils.quoteReplacement(item.LiquidationDate ?? ''),
        Ogrn: item.Ogrn ?? '',
        ParticipationType: LegalEntitiesParticipationUtils.convertParticipationToString(participationTypeLabel) || item.Position || '',
        Percent: item.Percent ?? 0,
        Position: StringUtils.quoteReplacement(item.Position ?? ''),
        PrincipalActivity: StringUtils.quoteReplacement(item.PrincipalActivity ?? ''),
        RegistrationDate: StringUtils.quoteReplacement(item.RegistrationDate ?? ''),
        RiskIndicator: item.RiskIndicator ?? ''
      };
    });
  }
}
