import { inject, injectable } from 'inversify';
import { ReportConnectionRelatedDTO } from '~/types/dto/common/reportConnectionRelatedDTO';
import { ReportConnectionRelated } from '~/types/common/reportConnectionRelated';
import {
  IReportConnectionRelatedAdapterService,
  IReportConnectionRelatedEntitiesAdapterService,
  IReportConnectionRelatedEntitiesAdapterServiceKey,
  IReportConnectionRelatedRelationsAdapterService,
  IReportConnectionRelatedRelationsAdapterServiceKey,
} from '~/services/adapters/common/reportAdapterService.interfeces';

@injectable()
export class ReportConnectionRelatedAdapterService implements IReportConnectionRelatedAdapterService {
  constructor(
    @inject(IReportConnectionRelatedEntitiesAdapterServiceKey as symbol)
    private reportConnectionRelatedEntitiesAdapterService: IReportConnectionRelatedEntitiesAdapterService,
    @inject(IReportConnectionRelatedRelationsAdapterServiceKey as symbol)
    private reportConnectionRelatedRelationsAdapterService: IReportConnectionRelatedRelationsAdapterService
  ) {}
  getData(initData: ReportConnectionRelated): ReportConnectionRelatedDTO[] {
    return (initData.Chains ?? []).map((chain) => ({
      Entities: this.reportConnectionRelatedEntitiesAdapterService.getData(chain.Entities),
      Relations: this.reportConnectionRelatedRelationsAdapterService.getData(chain.Relations)
    }));
  }
}
