import { InjectionKey } from 'vue';
import { CompanyDataProfiles } from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/types/companyDataProfiles';
import { LegalEntitiesCompanyManagementCompaniesDTO } from '~/types/dto/legalEntities/companyManagementCompaniesDTO';
import { LegalEntitiesCompanyHeadFlDTO } from '~/types/dto/legalEntities/companyHeadFlDTO';

interface ILegalEntitiesGeneralInformationDataProfileService<T> {
  getDataProfile(initData: Array<T>): Array<CompanyDataProfiles>;
}

export type ILegalEntitiesGeneralInformationManagementDataProfileService = ILegalEntitiesGeneralInformationDataProfileService<LegalEntitiesCompanyManagementCompaniesDTO>;
export type ILegalEntitiesGeneralInformationLeaderDataProfileService = ILegalEntitiesGeneralInformationDataProfileService<LegalEntitiesCompanyHeadFlDTO>;

export const ILegalEntitiesGeneralInformationManagementDataProfileServiceKey: InjectionKey<ILegalEntitiesGeneralInformationManagementDataProfileService> = Symbol('ILegalEntitiesGeneralInformationManagementDataProfileService');
export const ILegalEntitiesGeneralInformationLeaderDataProfileServiceKey: InjectionKey<ILegalEntitiesGeneralInformationLeaderDataProfileService> = Symbol('ILegalEntitiesGeneralInformationLeaderDataProfileService');
