import { injectable, inject } from 'inversify';

import { ReportVehicle } from '~/types/common/reportVehicle';
import { ReportVehicleRisks } from '~/types/common/reportVehicleRisks';
import { IPlaceholderDataService, IReportVehicleRisksPlaceholderServiceKey } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportVehiclePlaceholderService implements IPlaceholderDataService<ReportVehicle> {
  constructor(
    @inject(IReportVehicleRisksPlaceholderServiceKey as symbol) private reportVehicleRisksPlaceholderService: IPlaceholderDataService<ReportVehicleRisks>,
  ) {}

  getData(): ReportVehicle {
    return {
      CarNumber: '',
      Vin: '',
      Brand: '',
      Model: '',
      BrandSourceValue: '',
      ModelSourceValue: '',
      VerificationCode: 0,
      Year: 0,
      Color: '',
      OwnersCount: 0,
      OwnerShipPeriod: 0,
      OwnershipHistory: [],
      Relations: [],
      VehicleInsurances: [],
      PhotoUrls: [],
      VehicleRestricts: [],
      RoadAccidents: [],
      Wanted: [],
      VehiclePhones: [],
      PledgesRegistries: [],
      Risks: this.reportVehicleRisksPlaceholderService.getData()
    };
  }
}
